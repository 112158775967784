/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react'

type DesignBuilderContext = {
  theme: string
  setTheme: (theme: string) => void
  logo: string
  setLogo: (logo: string) => void
}

const defaultContext: DesignBuilderContext = {
  theme: '',
  setTheme: () => {},
  logo: '',
  setLogo: () => {}
}

export const DesignBuilderContext = createContext<DesignBuilderContext>(
  defaultContext
)

export const DesignBuilderContextProvider = ({ children }) => {
  const [theme, setTheme] = useState('')
  const [logo, setLogo] = useState('')

  return (
    <DesignBuilderContext.Provider
      value={{
        theme,
        setTheme,
        logo,
        setLogo
      }}
    >
      {children}
    </DesignBuilderContext.Provider>
  )
}

export default DesignBuilderContextProvider
