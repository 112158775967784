import { CircularProgress } from '@epilot/journey-elements'
import React from 'react'

import classes from './styles/SpinnerPage.module.scss'

export const SpinnerPage = () => {
  return (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  )
}
