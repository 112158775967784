import {
  blockController,
  CONTROL_NAME,
  Journey
} from '@epilot/journey-logic-commons'

export const extractLauncherJourneyLauncherBlock = (journey: Journey) =>
  blockController.findBlock(journey.steps, {
    type: CONTROL_NAME.JOURNEY_LAUNCHER_CONTROL
  })
