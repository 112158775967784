import '../assets/Typography.css';var q = Object.defineProperty;
var t = Object.getOwnPropertySymbols;
var e = Object.prototype.hasOwnProperty, l = Object.prototype.propertyIsEnumerable;
var n = (o, s, h) => s in o ? q(o, s, { enumerable: !0, configurable: !0, writable: !0, value: h }) : o[s] = h, p = (o, s) => {
  for (var h in s || (s = {}))
    e.call(s, h) && n(o, h, s[h]);
  if (t)
    for (var h of t(s))
      l.call(s, h) && n(o, h, s[h]);
  return o;
};
var y = (o, s) => {
  var h = {};
  for (var r in o)
    e.call(o, r) && s.indexOf(r) < 0 && (h[r] = o[r]);
  if (o != null && t)
    for (var r of t(o))
      s.indexOf(r) < 0 && l.call(o, r) && (h[r] = o[r]);
  return h;
};
import { jsx as f } from "react/jsx-runtime";
import { c as T } from "./index-rKuIKazb.js";
import { forwardRef as b } from "react";
const g = "_root_13lqh_1", N = "_h1_13lqh_5", x = "_h2_13lqh_9", C = "_h3_13lqh_13", j = "_h4_13lqh_17", u = "_h5_13lqh_21", v = "_h6_13lqh_25", w = "_p_13lqh_29", R = "_primary_13lqh_34", k = "_secondary_13lqh_38", z = "_error_13lqh_42", A = "_disabled_13lqh_46", _ = {
  root: g,
  h1: N,
  h2: x,
  h3: C,
  h4: j,
  h5: u,
  h6: v,
  p: w,
  primary: R,
  secondary: k,
  error: z,
  disabled: A
}, B = {
  h1: _.h1,
  h2: _.h2,
  h3: _.h3,
  h4: _.h4,
  h5: _.h5,
  h6: _.h6,
  p: _.p
}, D = b(
  (o, s) => {
    const c = o, { as: h = "p", style: r, className: m, variant: d = "primary" } = c, i = y(c, ["as", "style", "className", "variant"]), a = h;
    return /* @__PURE__ */ f(
      a,
      p({
        className: T(
          "Concorde-Typography",
          _.root,
          B[a],
          _[d],
          m
        ),
        ref: s,
        style: r
      }, i)
    );
  }
);
D.displayName = "Typography";
export {
  D as T
};
