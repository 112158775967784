import { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'

import { PrivateJourneyErrorWrapper, sessionIdGetter } from './App'
import { instance18n } from './locales/i18n'

type ConfigCheckWrapper = React.PropsWithChildren<unknown>

export function ConfigCheckWrapper({ children }: ConfigCheckWrapper) {
  const isEnvMissing =
    process.env.NODE_ENV !== 'development' &&
    (!window.journeyAppConfig ||
      Object.keys(window.journeyAppConfig).length === 0)

  useEffect(() => {
    if (isEnvMissing) {
      console.error('Journey app config is missing', sessionIdGetter())
    }
  }, [isEnvMissing])

  if (isEnvMissing) {
    return (
      <I18nextProvider i18n={instance18n}>
        <PrivateJourneyErrorWrapper embedMode={'inline'} />
      </I18nextProvider>
    )
  }

  return <>{children}</>
}
