import { getContrastRatio, rgbToHex } from '@epilot/journey-elements'

export const getIconColor = ({
  backgroundColor,
  mainColor
}: {
  backgroundColor: string
  mainColor: string
}) => {
  const isBackgroundColorCloseToWhite =
    getContrastRatio(backgroundColor, '#ffffff') < 2

  const isBackgroundColorCloseToMainColor =
    getContrastRatio(backgroundColor, rgbToHex(mainColor).slice(0, 7)) < 2

  if (isBackgroundColorCloseToWhite && isBackgroundColorCloseToMainColor) {
    return 'black'
  }
  if (isBackgroundColorCloseToWhite) {
    return rgbToHex(mainColor).slice(0, 7)
  }

  return 'white'
}
