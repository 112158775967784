import type { Journey } from '@epilot/journey-logic-commons'

/**
 * Retrieves the query parameters that are defined in the context schema
 * @param queryParams passed from the consuming website
 * @param contextValues defined in the journey config
 */
export const getParamsByContextValues = (
  queryParams: Record<string, string>,
  contextValues: Journey['contextSchema']
) => {
  const params: Record<string, string> = {}

  if (!contextValues) {
    return params
  }

  Object.keys(queryParams).forEach((key) => {
    if (contextValues.some(({ paramKey }) => key === paramKey)) {
      params[key] = queryParams[key]
    }
  })

  return params
}
