import { datadogRum } from '@datadog/browser-rum'
import { ThemeProvider as ConcordeThemeProvider } from '@epilot/concorde-elements'
import {
  ThemeProvider as MuiThemeProvider,
  CssBaseline
} from '@epilot/journey-elements'
import {
  LogicTriggerEventName,
  findStepIndexById,
  generateSources,
  isLauncherJourney,
  computeMaxLengthOfStepChains,
  computePossibleNextStepMap,
  computeDefaultNextStepMap,
  ACTION_TYPE
} from '@epilot/journey-logic-commons'
import type { Journey, StepState } from '@epilot/journey-logic-commons'
import {
  JOURNEY_EXIT_EVENT,
  JOURNEY_NAVIGATION_EVENT,
  NavigationProvider,
  useJourneyContext,
  makeJourneyEmptyStepValuesWithDefaults,
  useListener
} from '@epilot/json-renderers'
import type {
  EventDetailType,
  NavigationContextValue,
  ValueError,
  JourneyNavigationEventPayload,
  JourneyExitEventPayload,
  JourneyRenderFlags,
  HistoryStackState
} from '@epilot/json-renderers'
import isEqual from 'fast-deep-equal/react'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { PrivateJourneyErrorWrapper } from '../../App'
import { useAnalytics } from '../../context/AnalyticsContext'
import { useInitiateProductSelection } from '../../hooks/useInitiateProductSelection'
import { useIsDirty } from '../../hooks/useIsDirty'
import { getNextNonSkippedStepIndex } from '../../hooks/useLogics/applySkipStepLogics'
import { useLogics } from '../../hooks/useLogics/useLogics'
import type { ApplyLogicsNavigationCallback } from '../../hooks/useLogics/useLogics'
import type { ContextData } from '../../hooks/useMessageHandler/types'
import useUpdateTheme from '../../hooks/useUpdateTheme'
import {
  SubmissionError,
  submissionListener
} from '../../listeners/submissionListener'
import { extractLauncherJourneyLauncherBlock } from '../../utils/extractLauncherJourneyLauncherBlock'
import { findDuplicateStepId } from '../../utils/findDuplicateStepId'
import {
  getBlockTypesForError,
  getHigherPriorityErrorKey
} from '../../utils/journeyErrorUtils'
import { prepareTrackingData } from '../../utils/prepareTrackingData'
import { unreachable } from '../../utils/unreachable'
import { getWhichBlockHasChanged } from '../LogicEditor/utils'
import type { StepComponentProps } from '../StepComponent'

import {
  publishExitFullScreenMessage,
  publishEnterFullScreenMessage,
  publishCloseJourneyMessage,
  publishJourneyLoadedMessage
} from './embedJourneyPublishers'
import { dispatchExitEvent, dispatchLoadingEvent } from './eventsDispatchers'
import { LinearJourney } from './LinearJourney'
import type { HandleNavigationToStepOptions } from './types'
import {
  decodeTheme,
  getValueErrors,
  isNavigationToLinkedJourneyAllowed,
  transformMuiThemeToConcordeTheme,
  useJourneyThemeTransformed
} from './utils'

export type JourneyPageProps = {
  journey: Journey
  launcherJourney: Journey | null
  onJourneyChange: (journey: Journey) => void
  initialStepValues?: StepState[]
  flags: JourneyRenderFlags
  contextData: ContextData
  history: HistoryStackState
  launcherBlockName?: string
  embedMode?: 'full-screen' | 'inline'
}

export const JourneyPage = ({
  journey,
  onJourneyChange,
  initialStepValues,
  flags,
  contextData,
  history,
  launcherJourney,
  embedMode
}: JourneyPageProps) => {
  const { context, updateContext } = useJourneyContext()
  const { analytics } = useAnalytics()
  const { t } = useTranslation()

  const parentJourney = launcherJourney ?? journey
  const activeLinkedJourney = context._activeLinkedJourneyId
    ? context._linkedJourneyMap?.[context._activeLinkedJourneyId]
    : undefined
  const activeJourney = activeLinkedJourney ?? journey
  const launcherBlockName = useMemo(() => {
    const launcherBlock =
      launcherJourney && extractLauncherJourneyLauncherBlock(launcherJourney)

    return launcherBlock?.name
  }, [launcherJourney])

  const isParentJourneyLauncher = useMemo(
    () => parentJourney && isLauncherJourney(parentJourney.steps),
    [parentJourney]
  )

  const isCurrentJourneyLauncher = useMemo(
    () => journey && isLauncherJourney(journey.steps),
    [journey]
  )

  const isLinkedJourneyFromLauncher = useMemo(
    () => !isCurrentJourneyLauncher && isParentJourneyLauncher,
    [isCurrentJourneyLauncher, isParentJourneyLauncher]
  )

  const [submitJourneySuccess, setSubmitJourneySuccess] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)

  /**
   * This control state flag is used to prevent the journey from going into fullscreen mode,
   * when navigating back from a linked journey to a parent journey, given the parent journey to linked step
   * behavior triggers the fullscreen mode.
   */
  const [isNavigatingBack, setIsNavigatingBack] = useState(false)

  // used to send InitiateProductSelection event to website
  useInitiateProductSelection(
    activeJourney,
    history.currentIndex,
    history.stack
  )

  const initialStepsForCurrentJourney = useMemo(
    () =>
      makeJourneyEmptyStepValuesWithDefaults(activeJourney, initialStepValues),
    [initialStepValues, activeJourney]
  )

  const isDirty = useIsDirty(
    initialStepsForCurrentJourney,
    context._stepsStateArray,
    journey.journeyId,
    history.stack
  )

  /**
   * Publish event when journey loads
   */
  useEffect(() => {
    publishJourneyLoadedMessage({ journeyId: journey.journeyId })
  }, [journey.journeyId])

  const stepsErrors =
    context._errors.length === activeJourney.steps.length
      ? context._errors
      : Array.from<unknown, ValueError[]>(
          { length: activeJourney.steps.length },
          () => []
        )

  const currentStepIndex = history.currentIndex
  const stepsState = context._stepsStateArray

  const isLinkedJourneyInitialStep = useMemo(
    () => context?._linkedJourneyInitialStepIndex === currentStepIndex,
    [context, currentStepIndex]
  )

  /**
   * The state is expected in different shapes depending on whether `journey`
   * is a launcher journey or not. If we're currently displaying the launcher journey,
   * the state needs to be contained in an with a single step, containing a key named after
   * the launcher journey block which holds the state of the currently selected journey.
   * Otherwise, the state is simply the array of step states we get from context.
   */
  const linearJourneyStepsState = useMemo(() => {
    if (isCurrentJourneyLauncher && launcherBlockName) {
      return [{ [launcherBlockName]: context._stepsStateArray[0] ?? {} }]
    } else {
      return context._stepsStateArray
    }
  }, [isCurrentJourneyLauncher, launcherBlockName, context._stepsStateArray])

  const currentStep = activeJourney.steps[currentStepIndex] || {}
  const currentStepErrors = stepsErrors[currentStepIndex] || []

  /**
   * data injection is deprecated
   */
  const injectStateAndNavigateToStep: ApplyLogicsNavigationCallback = (
    stepIndexCandidate,
    localStepsState
  ) => {
    const stepIndex = getNextNonSkippedStepIndex(
      stepIndexCandidate,
      skipStepConditionsStatusRef.current,
      possibleDefaultNextStepMap
    )

    const newState = localStepsState ?? stepsState

    if (stepIndex !== currentStepIndex) {
      navigateToStep(stepIndex)
    }

    onStepStateChange({ newState, stepIndex })

    updateContext((context) => ({
      ...context,
      errorValidationMode: 'ValidateAndHide'
    }))
  }

  const {
    navigationLogics,
    applyLogics,
    displayConditionsStatusRef,
    skipStepConditionsStatusRef,
    resetInitializedLogics,
    isCurrentStepReadyToRender
  } = useLogics({
    journey: activeJourney,
    isPreview: flags.isPreview,
    currentStepIndex,
    onJourneyChange,
    injectStateAndNavigateToStep,
    stepsState,
    currentStep
  })

  const possibleDefaultNextStepMap = useMemo(() => {
    if (activeJourney.journeyId && activeJourney.steps) {
      return computeDefaultNextStepMap(activeJourney.steps)
    }

    return {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeJourney.journeyId])

  const possibleNextStepMap = useMemo(
    () => computePossibleNextStepMap(activeJourney.steps, navigationLogics),
    [activeJourney.steps, navigationLogics]
  )

  const remainingSteps = useMemo(() => {
    if (flags.isPreview) {
      // if in preview mode, simply show sequential progress bar
      return activeJourney.steps.length - currentStepIndex
    } else {
      const currentStepId = currentStep?.stepId ?? currentStep?.name

      if (currentStepId) {
        const maxLengthOfStepChain = computeMaxLengthOfStepChains(
          possibleNextStepMap,
          currentStepId
        )

        return maxLengthOfStepChain - 1
      }
    }
  }, [
    activeJourney.steps.length,
    currentStep?.name,
    currentStep?.stepId,
    currentStepIndex,
    flags.isPreview,
    possibleNextStepMap
  ])

  useEffect(
    function triggerFullscreenOnActiveLinkedJourney() {
      if (flags.isPreview) {
        return
      }

      const isLinkedJourneyInitialStep =
        context._linkedJourneyInitialStepIndex === currentStepIndex

      if (
        isLinkedJourneyFromLauncher &&
        isLinkedJourneyInitialStep &&
        !isFullscreen &&
        !isNavigatingBack
      ) {
        setIsFullscreen(true)
        publishEnterFullScreenMessage({
          journeyId: parentJourney.journeyId,
          isLauncherJourney: isParentJourneyLauncher
        })
      }
    },
    [
      context._linkedJourneyInitialStepIndex,
      currentStepIndex,
      flags.isPreview,
      isFullscreen,
      isLinkedJourneyFromLauncher,
      isNavigatingBack,
      isParentJourneyLauncher,
      parentJourney.journeyId
    ]
  )

  // updating initial State
  /**
   * Will only be executed on first render.
   * @todo Review its' purpose
   */
  useEffect(() => {
    if (initialStepValues && !isEqual(initialStepValues, stepsState)) {
      updateContext((prevState) => ({
        ...prevState,
        journeyStepStateMap: {
          ...prevState.journeyStepStateMap,
          [journey.journeyId]: initialStepValues
        }
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Gets called prior to navigating to a new step,
   * if there are errors for the current step.
   */
  const updateJourneyContextWithError = () => {
    const erroredBlockTypes = getBlockTypesForError(
      currentStepErrors,
      activeJourney,
      currentStepIndex
    )

    /**
     * Undefined error blocks -> generic error
     * 1 error block -> try to get the error key specific for the block
     * More than one error blocks -> show higher priority error or generic one
     */
    const errorMessageTranslationKey: string = getHigherPriorityErrorKey(
      erroredBlockTypes || undefined
    )

    updateContext((context) => ({
      ...context,
      errorValidationMode: 'ValidateAndShow',
      _errors: [...stepsErrors],
      errorMessageTranslationKey,
      erroredStepIndex: currentStepIndex,
      erroredJourneyId: context._activeLinkedJourneyId
    }))
  }

  const navigateToNextStep = async (targetStepId: string) => {
    if (currentStepErrors.length > 0) {
      updateJourneyContextWithError()

      return
    }

    if (!flags.isPreview) {
      const appliedLogicsInfo = await applyLogics(
        stepsState,
        currentStepIndex,
        LogicTriggerEventName.NEXT
      )

      if (appliedLogicsInfo.journey) {
        onJourneyChange(appliedLogicsInfo.journey)
      }

      if (typeof appliedLogicsInfo.navigationTargetStep === 'number') {
        injectStateAndNavigateToStep(
          appliedLogicsInfo.navigationTargetStep,
          stepsState
        )
      } else {
        // next step index is:
        // if targetStepId is passed then it is the step that has stepId == targetStepId, but if none is found, then look in the step names
        // if targetStepId is NOT passed, then it is currentStepIndex + 1
        const nextStepIndex = targetStepId
          ? findStepIndexById(
              activeJourney.steps,
              targetStepId,
              currentStepIndex + 1
            )
          : currentStepIndex + 1

        injectStateAndNavigateToStep(nextStepIndex)
      }
    }
  }

  const navigateToStep = (stepIndex: number) => {
    if (stepIndex === 0) {
      /* We're moving back to initial step */
      if (context._linkedJourneyMap) {
        onJourneyChange(parentJourney)
      }
      history.push(parentJourney.steps[stepIndex].name, {
        stepIndex,
        stack: history.stack,
        userValues: prepareTrackingData(stepsState, journey.steps)
      })
    } else {
      analytics?.addEvents([
        {
          type: 'page_navigation',
          details: {
            to: stepIndex + 1,
            from: currentStepIndex + 1
          },
          timestamp: new Date().toISOString()
        }
      ])
      /* Moving to any step that is not the initial step */
      if (isCurrentJourneyLauncher && activeLinkedJourney) {
        /* In case we're navigating from a launcher journey to a linked journey */
        const stepState = context._stepsStateArray[0] as StepState

        onJourneyChange(activeLinkedJourney)

        updateContext((context) => ({
          ...context,
          _activeLinkedJourneyId: undefined,
          _linkedJourneyInitialStepIndex: stepIndex
        }))

        /* We need to check whether it's possible to navigate */
        const canNavigateToLinkedJourney =
          isNavigationToLinkedJourneyAllowed(activeLinkedJourney, stepState) &&
          Boolean(activeLinkedJourney.steps[stepIndex])

        /**
         * If there are errors within the first step of the child journey, prevent navigation
         * @todo Find way to have error be displayed in this initial step
         */
        if (canNavigateToLinkedJourney) {
          history.push(activeLinkedJourney.steps[stepIndex].name, {
            stepIndex,
            stack: history.stack,
            userValues: prepareTrackingData(stepsState, journey.steps)
          })
        }
      } else {
        /* Navigate normally within the journey */
        const step = journey.steps[stepIndex]

        if (step) {
          history.push(step.name, {
            stepIndex,
            stack: history.stack,
            userValues: prepareTrackingData(stepsState, journey.steps)
          })
        } else {
          console.error(new Error('Next step not found'))
        }
      }
    }
  }

  const onStepStateChange = async ({
    newState,
    stepIndex,
    valueErrors,
    triggerBlockNames
  }: {
    newState: StepState | StepState[]
    stepIndex: number
    valueErrors?: ValueError[]
    triggerBlockNames?: string[]
  }) => {
    let updatedJourney: Journey | undefined = undefined
    let tempStepsState = [...stepsState]
    const errors = [...stepsErrors]

    if (Array.isArray(newState)) {
      tempStepsState = [...newState]
    } else {
      // Only dispatch event when the data is changed by the user
      window.dispatchEvent(
        new CustomEvent('journey-state-update', {
          detail: { newState, stepIndex }
        })
      )
      tempStepsState[stepIndex] = { ...newState }
    }

    if (valueErrors && !isEqual(errors[currentStepIndex], valueErrors)) {
      errors[currentStepIndex] = valueErrors
    }

    if (!flags.isPreview) {
      const appliedLogicsInfo = await applyLogics(
        tempStepsState,
        currentStepIndex,
        LogicTriggerEventName.VALUE_CHANGE,
        triggerBlockNames
      )

      if (typeof appliedLogicsInfo.navigationTargetStep === 'number') {
        injectStateAndNavigateToStep(
          appliedLogicsInfo.navigationTargetStep,
          tempStepsState
        )
      }

      if (appliedLogicsInfo.journey) {
        updatedJourney = appliedLogicsInfo.journey
      }

      const updatedStepsState = appliedLogicsInfo.updatedStepsState || []

      tempStepsState = [...updatedStepsState]
    }

    updateContext((context) => {
      const journeyId = context._activeLinkedJourneyId ?? journey.journeyId

      return {
        ...context,
        _errors: errors,
        journeyStepStateMap: {
          ...context.journeyStepStateMap,
          [journeyId]: tempStepsState.map((element) => {
            if (isCurrentJourneyLauncher && 'data' in element) {
              return (element.data as StepState) ?? {}
            }

            return element ?? {}
          })
        }
      }
    })

    if (updatedJourney) {
      onJourneyChange(updatedJourney)
    }
  }

  const alertNavigationIsDisabledWithinPreview = useCallback(() => {
    alert(
      t(
        'navigationIsDisabledWithinPreview',
        'Die Navigation innerhalb der Vorschau ist deaktiviert. Navigiere im Journey Builder über die Schritte am unteren Bildschirmrand.'
      )
    )
  }, [t])

  // a function that checkes the journey config if it includes steps with the same stepId
  // if so, it will create an alert via DD
  // it is using useEffect so it works in the journey preview too
  useEffect(() => {
    const duplicateStepIds = findDuplicateStepId(journey.steps)

    if (duplicateStepIds.length > 0) {
      datadogRum.addError(
        `The journey ${
          journey.journeyId
        } was found with duplicate stepIds: ${duplicateStepIds.join(', ')}`,
        {
          orgId: journey.organizationId,
          journeyId: journey.journeyId
        }
      )
      console.error(
        new Error(
          `The journey ${
            journey.journeyId
          } was found with duplicate stepIds: ${duplicateStepIds.join(', ')}`
        )
      )
    }

    if (flags.isPreview) {
      cleanJourneyState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journey])

  const cleanJourneyState = useCallback(() => {
    updateContext((context) => ({
      ...context,
      journeyStepStateMap: {
        ...context.journeyStepStateMap,
        [context._activeLinkedJourneyId ?? journey.journeyId]: Array.from(
          { length: journey.steps.length },
          () => ({})
        )
      },
      _errors: []
    }))

    resetInitializedLogics()
    setSubmitJourneySuccess(false)
  }, [
    journey.journeyId,
    journey.steps.length,
    updateContext,
    resetInitializedLogics
  ])

  const handleOnClose = useCallback(
    ({ isCleanJourneyData }: { isCleanJourneyData: boolean }) => {
      dispatchExitEvent({ isCleanJourneyData })
      publishCloseJourneyMessage({
        journeyId: journey.journeyId,
        isDirty
      })
      analytics?.addEvents([
        {
          type: 'journey_reset',
          details: {
            step: currentStepIndex + 1
          },
          timestamp: new Date().toISOString()
        }
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analytics, isDirty, journey.journeyId]
  )

  const handleGoBack = useCallback(async () => {
    setIsNavigatingBack(true)
    analytics?.addEvents([
      {
        type: 'page_navigation',
        details: {
          to: currentStepIndex,
          from: currentStepIndex + 1
        },
        timestamp: new Date().toISOString()
      }
    ])

    if (flags.isPreview) {
      alertNavigationIsDisabledWithinPreview()
    } else {
      const overridedState = [...stepsState]

      overridedState[currentStepIndex] = {}
      const appliedLogicsInfo = await applyLogics(
        overridedState,
        currentStepIndex,
        LogicTriggerEventName.NEXT
      )

      if (appliedLogicsInfo.journey) {
        onJourneyChange(appliedLogicsInfo.journey)
      }

      if (isParentJourneyLauncher && isLinkedJourneyInitialStep) {
        /**
         * @todo Consider whether isCleanJourneyData should be false in this condition
         */
        handleOnClose({ isCleanJourneyData: true })
      } else {
        history.back()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flags.isPreview,
    alertNavigationIsDisabledWithinPreview,
    stepsState,
    currentStepIndex,
    applyLogics,
    isParentJourneyLauncher,
    isLinkedJourneyInitialStep,
    onJourneyChange,
    handleOnClose,
    history
  ])

  /**
   * Called when
   * - User navigates with stepper
   * - Navigating from summary block
   * - Handling exit event fired by thank you block
   * @todo Consider how to combine this with handleGoBack and onNextStep
   */
  const handleNavigationToStep = useCallback(
    async (
      stepIndex: number,
      {
        shouldCleanInitialState,
        shouldReapplyLogics,
        shouldStepOutOfLauncherJourney
      }: HandleNavigationToStepOptions = {}
    ) => {
      if (flags.isPreview) {
        alertNavigationIsDisabledWithinPreview()

        return
      }

      analytics?.addEvents([
        {
          type: 'page_navigation',
          details: {
            to: stepIndex + 1,
            from: currentStepIndex + 1
          },
          timestamp: new Date().toISOString()
        }
      ])
      if (shouldReapplyLogics) {
        const overridedState = [...stepsState]

        for (let index = stepIndex; index < stepsState.length; index++) {
          overridedState[index] = {}
          const appliedLogicsInfo = await applyLogics(
            overridedState,
            index,
            LogicTriggerEventName.NEXT
          )

          if (appliedLogicsInfo.journey) {
            onJourneyChange(appliedLogicsInfo.journey)
          }
        }
      }

      if (stepIndex === 0) {
        if (shouldStepOutOfLauncherJourney) {
          /* Moving to first step */
          if (context._linkedJourneyMap) {
            onJourneyChange(parentJourney)
            if (shouldCleanInitialState) {
              cleanJourneyState()
            }
          }
          // if close button clicked, we need to use .push as we want to set isTrackingDisabled to false
          history.push(parentJourney.steps[stepIndex].name, {
            stepIndex,
            stack: [],
            userValues: undefined,
            isTrackingDisabled: true
          })
        } else {
          history.go(stepIndex)
        }
      } else {
        /* Moving to any step but first */
        // if there is a child, then load it
        if (activeLinkedJourney) {
          onJourneyChange(activeLinkedJourney)
        }
        history.go(stepIndex)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeLinkedJourney,
      alertNavigationIsDisabledWithinPreview,
      applyLogics,
      cleanJourneyState,
      context._linkedJourneyMap,
      flags.isPreview,
      history,
      onJourneyChange,
      parentJourney,
      stepsState
    ]
  )

  /**
   * @function handleAction Gets triggered when action buttons are pressed
   */
  const handleAction: NavigationContextValue['onAction'] = async (action) => {
    // only use the states that belong to the Steps in History
    const historyIndexes = history.stack.map(({ stepIndex }) => stepIndex)
    const historyErrors = stepsErrors.map((errors, index) =>
      historyIndexes.includes(index) ? errors : null
    )

    switch (action.actionType) {
      case ACTION_TYPE.GO_BACK: {
        handleGoBack()
        break
      }
      case ACTION_TYPE.GO_NEXT: {
        navigateToNextStep(action.targetStepId)
        setIsNavigatingBack(false)
        // after submission,, if we landed here by the user clicking next after submit, lets reset the journey submitted state
        if (submitJourneySuccess) {
          setSubmitJourneySuccess(false)
        }
        break
      }
      case ACTION_TYPE.SUBMIT_GO_NEXT: {
        setIsNavigatingBack(false)
        if (historyErrors[currentStepIndex]?.length) {
          updateJourneyContextWithError()
        } else if (!flags.isPreview) {
          if (historyErrors.some((errors) => errors?.length)) {
            console.warn(
              'Journey submitted with errors',
              journey.journeyId,
              historyErrors
            )
          }

          dispatchLoadingEvent(true)

          updateContext((currentContext) => ({
            ...currentContext,
            submissionErrorKey: undefined
          }))

          try {
            await submissionListener(
              activeJourney,
              stepsState,
              history,
              context,
              contextData,
              displayConditionsStatusRef.current,
              t
            )
            analytics?.addEvents([
              {
                type: 'journey_submit',
                details: {},
                timestamp: new Date().toISOString()
              }
            ])
            setSubmitJourneySuccess(true)
            navigateToNextStep(action.targetStepId)
          } catch (error) {
            setSubmitJourneySuccess(false)

            if (error instanceof SubmissionError) {
              updateContext((currentContext) => ({
                ...currentContext,
                /**
                 * @todo Remove type assertion once we bump TypeScript version
                 */
                submissionErrorKey: (error as SubmissionError).errorKey
              }))
            }
          } finally {
            dispatchLoadingEvent(false)
          }
        }
        break
      }
      default:
        unreachable(action)
    }
  }

  const journeyNavigationEventHandler = useCallback(
    ({
      detail: { payload }
    }: CustomEvent<EventDetailType<JourneyNavigationEventPayload>>) => {
      if (typeof payload?.stepIndex === 'number') {
        handleNavigationToStep(payload.stepIndex, {
          shouldReapplyLogics: true
        })
      }
    },
    [handleNavigationToStep]
  )

  const journeyExitEventHandler = useCallback(
    ({
      detail: { payload }
    }: CustomEvent<EventDetailType<JourneyExitEventPayload>>) => {
      if (payload.isCleanJourneyData || submitJourneySuccess) {
        cleanJourneyState()
      }

      if (flags.mode === 'full-screen' || isParentJourneyLauncher) {
        const { journeyId: currentJourneyId } = journey

        /**
         * On launcher journeys, the iframe to be adjusted is attached to
         * the launcher journey id instead of the current journey.
         */
        const journeyId = isParentJourneyLauncher
          ? parentJourney.journeyId
          : currentJourneyId

        setIsFullscreen(false)
        publishExitFullScreenMessage({
          journeyId,
          isLauncherJourney: isParentJourneyLauncher
        })
      }

      handleNavigationToStep(0, {
        shouldCleanInitialState: true,
        shouldStepOutOfLauncherJourney: true
      })
    },
    [
      cleanJourneyState,
      flags.mode,
      handleNavigationToStep,
      isParentJourneyLauncher,
      journey,
      parentJourney.journeyId,
      submitJourneySuccess
    ]
  )

  useListener<JourneyNavigationEventPayload>(
    JOURNEY_NAVIGATION_EVENT,
    journeyNavigationEventHandler
  )
  useListener<JourneyExitEventPayload>(
    JOURNEY_EXIT_EVENT,
    journeyExitEventHandler
  )

  const handleChange: StepComponentProps['onChange'] = (value) => {
    const { data } = value

    /**
     * If step journey launcher is being rendered, the step's newState
     * will be contained in the key named after the launcher block
     */
    const newState: StepState =
      launcherBlockName && launcherBlockName in data
        ? 'data' in data[launcherBlockName]
          ? data[launcherBlockName].data
          : data[launcherBlockName]
        : data

    const valueErrors = getValueErrors(
      isCurrentJourneyLauncher,
      value,
      currentStepIndex,
      newState,
      currentStep
    )

    const currentStepState = stepsState[currentStepIndex] ?? {}

    if (
      newState &&
      Object.keys(newState).length > 0 &&
      !isEqual(newState, currentStepState)
    ) {
      /* If state has changed */
      // handle navigation from Step events
      const triggerBlockNames = getWhichBlockHasChanged(
        currentStepState,
        newState
      )

      onStepStateChange({
        newState,
        stepIndex: currentStepIndex,
        valueErrors,
        triggerBlockNames
      })
    } else if (!isEqual(stepsErrors[currentStepIndex] ?? [], valueErrors)) {
      /* If state has not changed but errors have changed */
      const errors = [...stepsErrors]

      errors[currentStepIndex] = valueErrors
      updateContext((context) => ({
        ...context,
        _errors: errors
      }))
    }
  }

  const { theme: designBuilderTheme } = useUpdateTheme()

  const decodedTheme = decodeTheme(designBuilderTheme)

  const theme = useJourneyThemeTransformed(
    journey,
    decodedTheme ? decodedTheme : activeJourney?.design?.theme
  )

  /**
   * @todo Have _journeySources be computed within the context provider component
   */
  useEffect(() => {
    updateContext((context) => ({
      ...context,
      _journeySources: generateSources('*', activeJourney.steps)
    }))
  }, [activeJourney.steps, updateContext])

  const transformedTheme = useMemo(
    () => transformMuiThemeToConcordeTheme(theme),
    [theme]
  )

  if (!context._isPreview && context._missingContext) {
    return <PrivateJourneyErrorWrapper embedMode={embedMode || 'full-screen'} />
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ConcordeThemeProvider theme={transformedTheme}>
        <CssBaseline />
        <NavigationProvider onAction={handleAction}>
          {isCurrentStepReadyToRender && (
            <LinearJourney
              currentStepIndex={currentStepIndex}
              debug={flags.debug}
              isLinearJourney={flags.isLinear}
              isParentLauncherJourney={isParentJourneyLauncher}
              journey={journey}
              mode={flags.mode}
              onChange={handleChange}
              onCloseButtonClicked={() =>
                handleOnClose({ isCleanJourneyData: true })
              }
              onGoBack={handleGoBack}
              onNavigateToStep={handleNavigationToStep}
              remainingSteps={remainingSteps}
              showCloseButton={flags.showCloseButton}
              showTopBar={flags.showTopBar}
              stack={history.stack}
              stepsErrors={stepsErrors}
              stepsState={linearJourneyStepsState}
              submitJourneySuccess={submitJourneySuccess}
            />
          )}
        </NavigationProvider>
      </ConcordeThemeProvider>
    </MuiThemeProvider>
  )
}
