import { env } from './config'

export function setManifest(journeyId?: string) {
  const manifestJSON = {
    short_name: 'epilot',
    name: 'ePilot Journey',
    icons: [
      {
        src: 'favicon.ico',
        sizes: '64x64 32x32 24x24 16x16',
        type: 'image/x-icon'
      }
    ],
    start_url: journeyId ? env('REACT_APP_JOURNEY_URL') + journeyId : '.',
    display: 'standalone',
    theme_color: '#000000',
    background_color: '#ffffff',
    scope: env('REACT_APP_JOURNEY_BASE_URL')
  }

  const stringManifest = JSON.stringify(manifestJSON)
  const blob = new Blob([stringManifest], { type: 'application/json' })
  const manifestURL = URL.createObjectURL(blob)

  const htmlTag = document.querySelector('#my-manifest-placeholder')

  if (htmlTag) {
    htmlTag.setAttribute('href', manifestURL)
  }
}
