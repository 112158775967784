// eslint-disable-next-line import/no-named-as-default
import Analytics, { AnalyticsPlugin } from 'analytics'

import { addEvents, createSession, endSession } from './service'

const journeyAnalyticsPlugin: AnalyticsPlugin = {
  name: 'journeyAnalyticsPlugin',
  methods: {
    createSession,
    addEvents,
    endSession
  }
}

export const analytics: any = Analytics({
  app: 'epilot-journey-analytics',
  plugins: [journeyAnalyticsPlugin]
})
