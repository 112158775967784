/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import { JourneyEmbedOptions, PartialJourneyEmbedOptions } from "./types";

export function isJourneyEmbedOptions(obj: any, _argumentName?: string): obj is JourneyEmbedOptions {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        (obj.mode === "" ||
            obj.mode === "full-screen" ||
            obj.mode === "inline") &&
        (typeof obj.topBar === "undefined" ||
            obj.topBar === false ||
            obj.topBar === true) &&
        (typeof obj.scrollToTop === "undefined" ||
            obj.scrollToTop === false ||
            obj.scrollToTop === true) &&
        (typeof obj.closeButton === "undefined" ||
            obj.closeButton === false ||
            obj.closeButton === true) &&
        (typeof obj.lang === "undefined" ||
            typeof obj.lang === "string") &&
        (typeof obj.contextData === "undefined" ||
            (obj.contextData !== null &&
                typeof obj.contextData === "object" ||
                typeof obj.contextData === "function") &&
            Object.entries<any>(obj.contextData)
                .every(([key, _value]) => (typeof key === "string"))) &&
        (typeof obj.queryParams === "undefined" ||
            (obj.queryParams !== null &&
                typeof obj.queryParams === "object" ||
                typeof obj.queryParams === "function") &&
            Object.entries<any>(obj.queryParams)
                .every(([key, value]) => (typeof value === "string" &&
                    typeof key === "string"))) &&
        (typeof obj.dataInjectionOptions === "undefined" ||
            (obj.dataInjectionOptions !== null &&
                typeof obj.dataInjectionOptions === "object" ||
                typeof obj.dataInjectionOptions === "function") &&
            (typeof obj.dataInjectionOptions.initialStepIndex === "undefined" ||
                typeof obj.dataInjectionOptions.initialStepIndex === "number") &&
            (typeof obj.dataInjectionOptions.initialState === "undefined" ||
                Array.isArray(obj.dataInjectionOptions.initialState) &&
                obj.dataInjectionOptions.initialState.every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function")
                )) &&
            (typeof obj.dataInjectionOptions.blocksDisplaySettings === "undefined" ||
                Array.isArray(obj.dataInjectionOptions.blocksDisplaySettings) &&
                obj.dataInjectionOptions.blocksDisplaySettings.every((e: any) =>
                    (e !== null &&
                        typeof e === "object" ||
                        typeof e === "function") &&
                    e.type === "DISABLED" &&
                    typeof e.blockName === "string" &&
                    typeof e.stepIndex === "number" &&
                    (typeof e.blockFields === "undefined" ||
                        Array.isArray(e.blockFields) &&
                        e.blockFields.every((e: any) =>
                            typeof e === "string"
                        ))
                ))) &&
        (typeof obj.isFullScreenEntered === "undefined" ||
            obj.isFullScreenEntered === false ||
            obj.isFullScreenEntered === true)
    )
}

export function isPartialJourneyEmbedOptions(obj: any, _argumentName?: string): obj is PartialJourneyEmbedOptions {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        (typeof obj.mode === "undefined" ||
            obj.mode === "" ||
            obj.mode === "full-screen" ||
            obj.mode === "inline") &&
        (typeof obj.topBar === "undefined" ||
            obj.topBar === false ||
            obj.topBar === true) &&
        (typeof obj.scrollToTop === "undefined" ||
            obj.scrollToTop === false ||
            obj.scrollToTop === true) &&
        (typeof obj.closeButton === "undefined" ||
            obj.closeButton === false ||
            obj.closeButton === true) &&
        (typeof obj.lang === "undefined" ||
            typeof obj.lang === "string") &&
        (typeof obj.contextData === "undefined" ||
            (obj.contextData !== null &&
                typeof obj.contextData === "object" ||
                typeof obj.contextData === "function") &&
            Object.entries<any>(obj.contextData)
                .every(([key, _value]) => (typeof key === "string"))) &&
        (typeof obj.queryParams === "undefined" ||
            (obj.queryParams !== null &&
                typeof obj.queryParams === "object" ||
                typeof obj.queryParams === "function") &&
            Object.entries<any>(obj.queryParams)
                .every(([key, value]) => (typeof value === "string" &&
                    typeof key === "string"))) &&
        (typeof obj.dataInjectionOptions === "undefined" ||
            (obj.dataInjectionOptions !== null &&
                typeof obj.dataInjectionOptions === "object" ||
                typeof obj.dataInjectionOptions === "function") &&
            (typeof obj.dataInjectionOptions.initialStepIndex === "undefined" ||
                typeof obj.dataInjectionOptions.initialStepIndex === "number") &&
            (typeof obj.dataInjectionOptions.initialState === "undefined" ||
                Array.isArray(obj.dataInjectionOptions.initialState) &&
                obj.dataInjectionOptions.initialState.every((e: any) =>
                (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function")
                )) &&
            (typeof obj.dataInjectionOptions.blocksDisplaySettings === "undefined" ||
                Array.isArray(obj.dataInjectionOptions.blocksDisplaySettings) &&
                obj.dataInjectionOptions.blocksDisplaySettings.every((e: any) =>
                    (e !== null &&
                        typeof e === "object" ||
                        typeof e === "function") &&
                    e.type === "DISABLED" &&
                    typeof e.blockName === "string" &&
                    typeof e.stepIndex === "number" &&
                    (typeof e.blockFields === "undefined" ||
                        Array.isArray(e.blockFields) &&
                        e.blockFields.every((e: any) =>
                            typeof e === "string"
                        ))
                ))) &&
        (typeof obj.isFullScreenEntered === "undefined" ||
            obj.isFullScreenEntered === false ||
            obj.isFullScreenEntered === true)
    )
}
