import { jsx as d } from "react/jsx-runtime";
import { createContext as s, useContext as i, useEffect as p } from "react";
import '../assets/ThemeProvider.css';const a = {
  typography: {
    fontFamily: "ProximaNova, Open Sans, Helvetica, Arial, sans-serif",
    fontSize: 16,
    h1: {
      fontSize: 28
    },
    h2: {
      fontSize: 24
    },
    h3: {
      fontSize: 20
    },
    h4: {
      fontSize: 16
    },
    h5: {
      fontSize: 14
    },
    h6: {
      fontSize: 12
    }
  },
  palette: {
    primary: "#005EB4",
    secondary: "#913997",
    disabled: "#e0e2ec",
    error: "#ff3a3f",
    typography: {
      primary: "#001632",
      secondary: "#717171",
      disabled: "#43474e"
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF"
    }
  },
  spacing: 8,
  shape: {
    borderRadius: 4
  },
  breakpoints: { xs: 0, sm: 600, md: 960, lg: 1200, xl: 1536 }
}, n = s(void 0);
function u() {
  const o = i(n);
  if (o === void 0)
    throw new Error("useTheme must be used within a ThemeProvider");
  return o;
}
function l({
  children: o,
  theme: e = a
}) {
  return p(() => {
    const t = {
      "--concorde-primary-color": e.palette.primary,
      "--concorde-secondary-color": e.palette.secondary,
      "--concorde-disabled-color": e.palette.disabled,
      "--concorde-error-color": e.palette.error,
      "--concorde-font-family": e.typography.fontFamily,
      "--concorde-border-radius": `${e.shape.borderRadius}px`,
      "--concorde-spacing": `${e.spacing}px`,
      "--concorde-primary-text": e.palette.typography.primary,
      "--concorde-secondary-text": e.palette.typography.secondary,
      "--concorde-disabled-text": e.palette.typography.disabled
    };
    return Object.entries(t).forEach(([r, c]) => {
      document.documentElement.style.setProperty(r, c);
    }), () => {
      Object.keys(t).forEach((r) => {
        document.documentElement.style.removeProperty(r);
      });
    };
  }, [e]), /* @__PURE__ */ d(n.Provider, { value: a, children: o });
}
l.displayName = "ThemeProvider";
export {
  a as D,
  l as T,
  u
};
