import {
  Block_LOCATOR_SEPERATOR,
  blockController,
  Step,
  UiSchema,
  isLayoutUiSchema,
  isUiSchemaWithElements
} from '@epilot/journey-logic-commons'
import { ControlElement, Layout, UISchemaElement } from '@jsonforms/core'

export function changeBlockVisibilityState(
  targetStep: Step & { stepIndex: number },
  targetBlockName: string,
  display: boolean
) {
  const { uischema, schema } = targetStep
  const elements = isUiSchemaWithElements(uischema) ? uischema?.elements : []
  const required = schema.required || []
  const options = uischema?.options
  const block = blockController.findBlock(targetStep, { name: targetBlockName })
  const extraBlockNames: string[] = []

  if (block && isLayoutUiSchema(block.uischema)) {
    for (const element of block.uischema.elements) {
      const name = (element as UiSchema).scope.split('/').pop()

      name && extraBlockNames.push(name)
    }
  }

  if (options && !options.originalRequired) {
    options.originalRequired = [...required]
  }

  if (!display) {
    const newRequired = required.filter(
      (name) => targetBlockName !== name && !extraBlockNames.includes(name)
    )

    schema.required = newRequired
  } else {
    if (
      !required.includes(targetBlockName) &&
      options?.originalRequired.includes(targetBlockName)
    ) {
      const newRequired = [...required, targetBlockName]

      schema.required = newRequired
    }

    const extraBlockNamesToAdd = extraBlockNames.filter(
      (name) =>
        !required.includes(name) && options?.originalRequired.includes(name)
    )

    if (extraBlockNamesToAdd) {
      const newRequired = schema.required || []

      schema.required = [...newRequired, ...extraBlockNamesToAdd]
    }
  }

  setDisplayInUiSchema(
    elements as (UISchemaElement & Layout & ControlElement)[],
    targetBlockName,
    display
  )

  return extraBlockNames
}

function setDisplayInUiSchema(
  uiSchemaElements: (UISchemaElement & Layout & ControlElement)[],
  targetBlockName: string,
  display: boolean
) {
  for (const element of uiSchemaElements) {
    if (Array.isArray(element) && element.length > 0) {
      setDisplayInUiSchema(element, targetBlockName, display)
    } else if (element.scope) {
      const blockName = element.scope.split(Block_LOCATOR_SEPERATOR).pop()

      if (blockName === targetBlockName) {
        element.options = { ...element.options, display: display }
      }
    }
  }
}
