import isEmpty from 'lodash/isEmpty'

export const shadowFormEventListener = (
  e: CustomEvent<{ stepIndex: number; newState: Record<string, unknown> }>
) => {
  const formEl = document.querySelector<HTMLFormElement>('form#shadow-form')
  const { stepIndex, newState } = e.detail

  if (formEl) {
    updateShadowFormStepSate(newState, stepIndex, formEl)
  }
}

const updateShadowFormStepSate = (stepState, stepIndex, formEl) => {
  for (const [blockName, value] of Object.entries(stepState)) {
    if (
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      !isEmpty(value)
    ) {
      const inputName = `step-${stepIndex}-${blockName}`
      let inputElement = document.querySelector(`input[name="${inputName}"]`)

      if (!inputElement) {
        inputElement = document.createElement('input')

        inputElement.setAttribute('name', inputName)
        formEl?.appendChild(inputElement)
      }

      inputElement.setAttribute('value', JSON.stringify(value))
    }
  }
}

export const createShadowFormElement = () => {
  let formEl = document.querySelector('#shadow-form')

  if (!formEl) {
    formEl = document.createElement('form')

    formEl.setAttribute('id', 'shadow-form')
    formEl.setAttribute('style', 'display:none;')
    document.querySelector('body')?.appendChild(formEl)
  }

  window.addEventListener(
    'journey-state-update',
    shadowFormEventListener as EventListener
  )
}

export const getShadowFormData = () => {
  const formEl = document.querySelector<HTMLFormElement>('form#shadow-form')

  const formData = new FormData(formEl ?? undefined)

  return formData
}

export const getShadowFormDataJSON = (submissionPayload: unknown) => {
  const formData = getShadowFormData()
  const data = {}

  for (const [key, value] of Array.from(formData.entries())) {
    Object.assign(data, { [key]: JSON.parse(value as string) })
  }

  data['referrer'] = document.referrer
  data['submissionPayload'] = submissionPayload

  const json = JSON.stringify(data)

  return json
}
