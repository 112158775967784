import { H5, LargeText, Typography, clsx } from '@epilot/journey-elements'
import type { StepState, StepExtended } from '@epilot/journey-logic-commons'
import type { JsonFormsOnChange } from '@epilot/json-renderers'
import { useAdditionalErrorsFromState } from '@epilot/json-renderers'
import type { ValidationMode } from '@jsonforms/core'
import { JsonForms } from '@jsonforms/react'
import { useTranslation } from 'react-i18next'

import { initAJV } from '../utils/initAJV'
import { allRenderers } from '../utils/renderers'
import { IsMobile } from '../utils/tools'

import { useWrapperStyles } from './JourneyPage/styles'
import classes from './styles/StepComponent.module.scss'

export type StepComponentProps = {
  /**
   * @todo Consider accessing this property from context in component instead of having it drilled
   */
  validationMode: ValidationMode
  step: StepExtended
  data: StepState
  onChange: JsonFormsOnChange
}

const RequiredLabel = () => {
  const { t } = useTranslation()

  return (
    <Typography
      className={classes.label}
      color="textSecondary"
      variant="caption"
    >
      {'* ' + t('input_required', 'Pflichteingabe')}
    </Typography>
  )
}

export const StepComponent = ({
  data,
  step,
  validationMode,
  onChange
}: StepComponentProps) => {
  const ajv = initAJV()

  const additionalErrors = useAdditionalErrorsFromState(
    data,
    validationMode,
    step
  )
  const mobile = IsMobile()
  const { wrapperStyles } = useWrapperStyles()

  const hasRequiredField = Boolean(
    step.schema?.required && step.schema.required.length > 0
  )

  return (
    <>
      <div className={wrapperStyles}>
        <div
          className={
            step.showStepName || step.showStepSubtitle
              ? classes.titleContainer
              : ''
          }
        >
          <div
            className={clsx(
              classes.labelContainer,
              step.showStepName ? classes.spaceBetween : classes.flexEnd
            )}
          >
            {step.showStepName && (
              <div className={mobile ? '' : classes.desktopStepNameContainer}>
                <H5 color="textPrimary" gutterBottom={!!step.showStepSubtitle}>
                  <b
                    className={
                      mobile ? classes.mobileStepName : classes.desktopStepName
                    }
                  >
                    {step.title === undefined ? step.name : step.title}
                  </b>
                </H5>
              </div>
            )}
            {!mobile && hasRequiredField && <RequiredLabel />}
          </div>

          {step.showStepSubtitle && (
            <div
              className={
                mobile
                  ? classes.mobileSubtitleContainer
                  : classes.desktopSubtitleContainer
              }
            >
              <LargeText>
                <span
                  className={
                    mobile ? classes.mobileSubtitle : classes.desktopSubtitle
                  }
                >
                  {step.subTitle}
                </span>
              </LargeText>
            </div>
          )}
        </div>
        {mobile && hasRequiredField && (
          <div className={classes.requiredLabelContainer}>
            <RequiredLabel />
          </div>
        )}
      </div>
      <JsonForms
        additionalErrors={additionalErrors}
        ajv={ajv}
        data={data}
        onChange={onChange}
        renderers={allRenderers}
        schema={step.schema}
        uischema={step.uischema}
        validationMode={validationMode}
      />
    </>
  )
}
