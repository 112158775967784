import '../assets/Spacing.css';var y = Object.defineProperty;
var o = Object.getOwnPropertySymbols;
var e = Object.prototype.hasOwnProperty, l = Object.prototype.propertyIsEnumerable;
var c = (a, t, s) => t in a ? y(a, t, { enumerable: !0, configurable: !0, writable: !0, value: s }) : a[t] = s, r = (a, t) => {
  for (var s in t || (t = {}))
    e.call(t, s) && c(a, s, t[s]);
  if (o)
    for (var s of o(t))
      l.call(t, s) && c(a, s, t[s]);
  return a;
};
var m = (a, t) => {
  var s = {};
  for (var n in a)
    e.call(a, n) && t.indexOf(n) < 0 && (s[n] = a[n]);
  if (a != null && o)
    for (var n of o(a))
      t.indexOf(n) < 0 && l.call(a, n) && (s[n] = a[n]);
  return s;
};
import { jsx as u } from "react/jsx-runtime";
import { c as x } from "./index-rKuIKazb.js";
import { forwardRef as N } from "react";
const k = "_root_2nsp6_1", p = {
  root: k,
  "variant-inline": "_variant-inline_2nsp6_9",
  "variant-inset": "_variant-inset_2nsp6_14",
  "variant-stack": "_variant-stack_2nsp6_19"
}, b = N((a, t) => {
  const i = a, {
    alignItems: s = "flex-start",
    scale: n = 1,
    style: _,
    variant: v,
    className: g
  } = i, d = m(i, [
    "alignItems",
    "scale",
    "style",
    "variant",
    "className"
  ]), f = {
    "--concorde-spacing-scale": n,
    "--concorde-spacing-align-items": s
  }, S = r(r({}, _), f);
  return /* @__PURE__ */ u(
    "div",
    r({
      className: x(
        "Concorde-Spacing",
        p.root,
        p[`variant-${v}`],
        g
      ),
      ref: t,
      style: S
    }, d)
  );
});
b.displayName = "Spacing";
export {
  b as S
};
