import { Step, StepState } from '@epilot/journey-logic-commons'

/**
 * Constructs an object with default values from a step's schema properties.
 */
const constructDefaults = (step: Step): StepState =>
  Object.fromEntries(
    Object.entries(step.schema?.properties ?? {})
      .filter(([, property]) => 'default' in property)
      .map(([key, property]) => [key, property.default])
  )

/**
 * Populates an array of step states with defaults for the current step.
 */
export const populateStepsStateWithDefaults = (
  stepsState: StepState[],
  currentStep: Step,
  currentStepIndex: number
): StepState[] =>
  stepsState.map((stepState, index) =>
    index === currentStepIndex ? constructDefaults(currentStep) : stepState
  )
