import {
  getLogicsDisplayBlock,
  getLogicsInjectFilter,
  getLogicsNavigateFromStep,
  getLogicsSkipStep,
  isConditionTrue,
  Journey,
  logicStringsToObjects,
  LogicTriggerEventName,
  StepState,
  getAutoInjectionLogics,
  Step,
  DisplayConditionsStatus
} from '@epilot/journey-logic-commons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { injectFilterInStepConfig } from '../../utils/injectFilterInStepConfig'
import { SkipStepConditionsStatus } from '../../utils/types'

import { applyDisplayLogics } from './applyDisplayLogics'
import { applyFilterInjectionLogics } from './applyFilterInjectionLogics'
import { applySkipStepLogics } from './applySkipStepLogics'
import { populateStepsStateWithDefaults } from './utils'

export type ApplyLogicsNavigationCallback = (
  stepIndex: number,
  stepsState?: StepState[]
) => void

type UseLogicsProps = {
  currentStepIndex: number
  isPreview?: boolean
  onJourneyChange: (journey: Journey) => void
  stepsState: StepState[]
  currentStep: Step
  journey: Journey
  injectStateAndNavigateToStep: ApplyLogicsNavigationCallback
}

const DEFAULT_JOURNEY_LOGICS = []
const DEFAULT_JOURNEY_STEPS = []

export const useLogics = ({
  journey,
  isPreview,
  currentStepIndex,
  stepsState,
  currentStep,
  injectStateAndNavigateToStep,
  onJourneyChange
}: UseLogicsProps) => {
  const {
    logics = DEFAULT_JOURNEY_LOGICS,
    steps = DEFAULT_JOURNEY_STEPS
  } = journey
  const [initializedLogics, setInitializedLogics] = useState<boolean[]>([])
  const isNoLogicInitialized = initializedLogics.length === 0
  const isCurrentStepReadyToRender =
    initializedLogics[currentStepIndex] || isPreview
  const logicObjects = useMemo(() => logicStringsToObjects(logics), [logics])

  const navigationLogics = useMemo(
    () => getLogicsNavigateFromStep(steps, logicObjects),
    [steps, logicObjects]
  )

  const filterInjectionLogics = useMemo(() => {
    const logics = getLogicsInjectFilter(steps, logicObjects)

    // Auto logics are not relevant during preview
    if (isPreview) {
      return logics
    }

    const autoInjectionLogics = getAutoInjectionLogics(steps)

    return logics.map((logic, index) => [
      ...logic,
      ...autoInjectionLogics[index]
    ])
  }, [steps, logicObjects, isPreview])

  const displayBlockLogics = useMemo(
    () => getLogicsDisplayBlock(steps, logicObjects),
    [steps, logicObjects]
  )

  const skipStepLogics = useMemo(() => getLogicsSkipStep(steps, logicObjects), [
    steps,
    logicObjects
  ])

  const displayConditionsStatusRef = useRef<DisplayConditionsStatus>({})
  const skipStepConditionsStatusRef = useRef<SkipStepConditionsStatus>({})

  const applyLogics = useCallback(
    async (
      stepsState: StepState[],
      currentStepIndex: number,
      triggerEvent: LogicTriggerEventName,
      triggerBlockNames?: string[]
    ) => {
      const currentStepState = stepsState[currentStepIndex]

      /* DETECT NAVIGATION LOGIC */
      const navigationLogicsToApply =
        navigationLogics?.[currentStepIndex]?.filter((logic) => {
          const triggerKey = `${currentStepIndex}/${
            logic.parentBlockName || logic.blockName
          }`
          const displayConditionsStatus =
            displayConditionsStatusRef.current?.[triggerKey]

          if (displayConditionsStatus?.display === false) {
            return false
          }

          if (triggerEvent === LogicTriggerEventName.VALUE_CHANGE) {
            return (
              logic.conditionsRelatedToStep[0].event === triggerEvent &&
              logic.blockName === triggerBlockNames?.[0]
            )
          }

          return logic.conditionsRelatedToStep[0].event === triggerEvent
        }) || []

      // Evaluate navigation logics
      const evaluatedNavigationLogics = await Promise.all(
        navigationLogicsToApply.map((logic) => {
          if (!(logic.conditionsRelatedToStep && logic.actions)) {
            return Promise.resolve(null)
          }

          return isConditionTrue(
            logic.conditionsRelatedToStep[0],
            currentStepState[logic.blockName],
            {
              conditionLogicConfig: logic,
              journeyState: journey,
              journeyConfigMutator: (
                targetStepId: string,
                targetBlockName: string,
                targetFieldName: string,
                dataValue: unknown
              ) => {
                const targetStep = journey.steps.find(
                  (step) => step.stepId === targetStepId
                )

                if (targetStep) {
                  injectFilterInStepConfig(
                    targetStep,
                    targetBlockName,
                    targetFieldName,
                    dataValue
                  )
                }

                return journey
              }
            }
          )
        })
      )

      // Find the first one that resolves to true (can be undefined)
      const trueNavigationLogic = navigationLogicsToApply.find(
        (logic, index) => {
          const conditionValue = evaluatedNavigationLogics[index]

          return (
            conditionValue ===
            (typeof logic.actions?.[0].conditionResult === 'boolean'
              ? logic.actions[0].conditionResult
              : true)
          )
        }
      )

      /* DETECT AND APPLY NON NAVIGATION LOGICS */
      const newJourney = { ...journey }

      const applyFilterInjectionLogicsResult = await applyFilterInjectionLogics(
        newJourney,
        filterInjectionLogics,
        currentStepIndex,
        trueNavigationLogic,
        triggerEvent,
        triggerBlockNames?.[0],
        currentStepState
      )

      // Evaluate display logics
      const displayLogicsData = await applyDisplayLogics(
        newJourney,
        displayBlockLogics,
        stepsState,
        currentStepIndex,
        triggerEvent,
        displayConditionsStatusRef.current,
        triggerBlockNames
      )

      await applySkipStepLogics(
        skipStepLogics,
        newJourney.steps,
        stepsState,
        currentStepIndex,
        triggerEvent,
        skipStepConditionsStatusRef.current,
        triggerBlockNames
      )

      const appliedLogics =
        applyFilterInjectionLogicsResult + displayLogicsData.logicsCount

      return {
        navigationTargetStep:
          trueNavigationLogic?.actionsIncludesNav[0].targetStepIndex,
        journey: appliedLogics > 0 ? newJourney : undefined,
        updatedStepsState: displayLogicsData.updatedStepsState
      }
    },
    [
      displayBlockLogics,
      filterInjectionLogics,
      journey,
      skipStepLogics,
      navigationLogics
    ]
  )

  const resetInitializedLogics = useCallback(() => {
    setInitializedLogics([])
  }, [])

  /**
   * Run logics for steps which haven't had their logics initialized yet
   */
  useEffect(() => {
    if (!isPreview && !initializedLogics[currentStepIndex]) {
      const stepsStateWithDefaults = populateStepsStateWithDefaults(
        stepsState,
        currentStep,
        currentStepIndex
      )

      applyLogics(
        stepsStateWithDefaults,
        currentStepIndex,
        LogicTriggerEventName.VALUE_CHANGE
      ).then((appliedLogicsInfo) => {
        if (typeof appliedLogicsInfo.navigationTargetStep === 'number') {
          injectStateAndNavigateToStep(
            appliedLogicsInfo.navigationTargetStep,
            stepsStateWithDefaults
          )
        }

        if (appliedLogicsInfo.journey) {
          onJourneyChange(appliedLogicsInfo.journey)
        }
        // Used for telling the LinearJourney that it's ready for rendering
        setInitializedLogics((currentInitializedLogics) => {
          const newInitializedLogics = [...currentInitializedLogics]

          newInitializedLogics[currentStepIndex] = true

          return newInitializedLogics
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex, isNoLogicInitialized])

  return {
    navigationLogics,
    applyLogics,
    displayConditionsStatusRef,
    skipStepConditionsStatusRef,
    isCurrentStepReadyToRender,
    resetInitializedLogics
  }
}
