import {
  Journey,
  CONTROL_NAME,
  generateSources,
  RUNTIME_ENTITY
} from '@epilot/journey-logic-commons'
import isEmpty from 'lodash/isEmpty'

import type { DataSources } from './types'
export enum POSSIBLE_JOURNEY_ENTITIES {
  'ORDER',
  'FILES',
  'OPPORTUNITY',
  'CONSENTS'
}

export function generateJourneyDataSources(journey: Journey) {
  const dataSources: DataSources = {}
  const selectedEntities = journey.settings?.runtimeEntities || []

  const persons =
    generateSources(CONTROL_NAME.PERSONAL_INFORMATION_CONTROL, journey.steps) ||
    []

  const personsWithEmail = persons
    ? persons.filter(
        (block) =>
          block.uischema?.options &&
          ((block.uischema.options.fields &&
            block.uischema.options.fields.email &&
            block.uischema.options.fields.email.required) ||
            (block.uischema.options.requiredFields &&
              block.uischema.options.requiredFields.email))
      )
    : []

  const customerEmails = personsWithEmail?.filter((block) =>
    block.uischema?.options?.purposeLabels?.includes('customer')
  )

  if (customerEmails && customerEmails.length > 1) {
    console.error('Multiple customer emails were found in the journey')
  }

  if (
    selectedEntities &&
    selectedEntities.length > 0 &&
    selectedEntities.some(
      (entity) =>
        entity === RUNTIME_ENTITY.ORDER || entity === RUNTIME_ENTITY.OPPORTUNITY
    )
  ) {
    /**
     * ORDER Sources
     */
    const productSelectors =
      generateSources(CONTROL_NAME.PRODUCT_SELECTION_CONTROL, journey.steps) ||
      []
    const addresses =
      generateSources(CONTROL_NAME.ADDRESS_BLOCK, journey.steps) || {}
    const billing_address = addresses.find(
      (block) => typeof block !== 'string' && block.uischema?.options?.isBilling
    )
    const delivery_address = addresses.find(
      (block) =>
        typeof block !== 'string' && block.uischema?.options?.isDelivery
    )
    const additional_addresses = addresses.filter(
      (block) =>
        typeof block !== 'string' &&
        block.uischema?.options &&
        !block.uischema.options.isBilling &&
        !block.uischema.options.isDelivery
    )

    const methods =
      generateSources(CONTROL_NAME.PAYMENT_CONTROL, journey.steps) || []

    // if the Journey has cart AND a product selector then we generate an order
    if (productSelectors.length > 0 && personsWithEmail.length > 0) {
      dataSources[POSSIBLE_JOURNEY_ENTITIES.ORDER] = {
        contactAddressLocation: delivery_address || undefined,
        billingAddressLocation: billing_address || undefined,
        additionalAddressesLocation:
          additional_addresses.length > 0 ? additional_addresses : undefined,
        paymentLocation: methods.length > 0 ? methods[0] : undefined,
        personLocation: isEmpty(customerEmails)
          ? personsWithEmail[0]
          : customerEmails[0]
      }
    }

    // if the Journey has personal info then it could send an OP
    if (personsWithEmail.length > 0) {
      dataSources[POSSIBLE_JOURNEY_ENTITIES.OPPORTUNITY] = {
        contactAddressLocation: delivery_address,
        billingAddressLocation: billing_address,
        paymentLocation: methods.length > 0 ? methods[0] : undefined,
        personLocation: isEmpty(customerEmails)
          ? personsWithEmail[0]
          : customerEmails[0]
      }
    }
  }

  /**
   * FILE SOURCES
   */
  const fileLocations = generateSources(
    CONTROL_NAME.UPLOAD_CONTROL,
    journey.steps
  )

  if (fileLocations.length > 0) {
    dataSources[POSSIBLE_JOURNEY_ENTITIES.FILES] = {
      fileLocations
    }
  }

  /**
   * CONSENT SOURCES
   */
  const consentsLocations =
    generateSources(CONTROL_NAME.CONSENTS_CONTROL, journey.steps) || []

  if (consentsLocations.length > 0 && personsWithEmail.length > 0) {
    dataSources[POSSIBLE_JOURNEY_ENTITIES.CONSENTS] = {
      locations: consentsLocations,
      userLocations: [...customerEmails, ...personsWithEmail]
    }
  }

  return dataSources
}
