import { useContext, useEffect } from 'react'

import { DesignBuilderContext } from '../context/DesignBuilderContext'
import { env, isLocalOriginEventAllowed } from '../utils/config'

export enum DesignBuilderUpdateEvent {
  UPDATE_THEME = 'JourneyUpdateThemeEvent'
}

export type EventDetailType = {
  type: DesignBuilderUpdateEvent.UPDATE_THEME
  payload: {
    theme: string
    logo: string
  }
}

const useUpdateTheme = () => {
  const { theme, setTheme, logo, setLogo } = useContext(DesignBuilderContext)

  useEffect(() => {
    const updateThemeEventHandler = (event: MessageEvent) => {
      const origin = event.origin

      if (
        origin !== env('REACT_APP_JOURNEY_BUILDER_URL') &&
        !isLocalOriginEventAllowed(origin)
      ) {
        return
      }

      const data = event.data

      if (
        typeof data == 'object' &&
        data !== null &&
        data?.detail?.eventCaller === DesignBuilderUpdateEvent.UPDATE_THEME
      ) {
        const { theme, logo } = data.detail.payload

        setTheme(theme)
        setLogo(logo)
      }
    }

    window.addEventListener('message', updateThemeEventHandler, false)

    return () => window.removeEventListener('message', updateThemeEventHandler)
  }, [setLogo, setTheme])

  return {
    theme,
    logo
  }
}

export default useUpdateTheme
