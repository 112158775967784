import type { ConcordeTheme } from '@epilot/concorde-elements'
import {
  EpilotTheme,
  DefaultEpilotTheme,
  EpilotTypography
} from '@epilot/journey-elements'
import {
  Journey,
  isBlockValid,
  StepState,
  isLauncherJourney,
  StepExtended,
  Step
} from '@epilot/journey-logic-commons'
import { ValueError } from '@epilot/json-renderers'
import type { JsonFormsCore } from '@jsonforms/core'
import { CSSProperties, useMemo } from 'react'

import { initAJV } from '../../utils/initAJV'

const defaultTheme = { ...structuredClone(DefaultEpilotTheme), spacing: 4 }

export const useJourneyThemeTransformed = (
  journey: Journey,
  activeJourneyTheme?: NonNullable<Journey['design']>['theme']
) =>
  useMemo<EpilotTheme>(() => {
    const isCurrentJourneyLauncher = journey && isLauncherJourney(journey.steps)

    const journeyTheme: EpilotTheme = {
      ...structuredClone(activeJourneyTheme ?? defaultTheme),
      breakpoints: { values: { xs: 0, sm: 600, md: 960, lg: 1200, xl: 1536 } }
    }

    /**
     * If we're viewing step 1 of a linked journey, we need to ensure the fonts
     * from the linked journey theme are injected by CssBaseline
     */
    const shouldAppendActiveJourneyFonts =
      isCurrentJourneyLauncher &&
      (activeJourneyTheme?.typography as EpilotTypography)?.fontSource

    if (shouldAppendActiveJourneyFonts) {
      journeyTheme.typography = {
        ...(journeyTheme.typography as EpilotTypography),
        fontSource: [
          ...(journeyTheme.typography?.fontSource ?? []),
          ...((activeJourneyTheme as EpilotTheme)?.typography?.fontSource ?? [])
        ]
      }
    }

    return journeyTheme
  }, [journey, activeJourneyTheme])

export const decodeTheme = (theme: string) => {
  if (theme !== undefined && theme !== null && theme !== '') {
    const parsedTheme = JSON.parse(atob(theme))

    return parsedTheme
  }

  return
}

export const getValueErrors = (
  isJourneyLaucher: boolean,
  value: Pick<JsonFormsCore, 'data' | 'errors'>,
  currentStepIndex: number,
  newState: StepState,
  currentStep: Step
) => {
  // check each block value for _isValid
  let blockErrors: ValueError[] = []

  blockErrors = computeBlockErrors(
    { ...currentStep, stepIndex: currentStepIndex },
    newState
  )

  let launcherErrors = []

  if (isJourneyLaucher) {
    launcherErrors = value.data['Launcher block'].errors
  }

  return [...(value.errors ?? []), ...blockErrors, ...(launcherErrors || [])]
}

const computeBlockErrors = (
  step: StepExtended,
  state: StepState
): ValueError[] => {
  if (!state) {
    return []
  }

  return Object.keys(state)
    .filter((blockName) => !isBlockValid(step, state, blockName))
    .map<ValueError>((blockName) => ({
      instancePath: `/${blockName}`,
      message: 'Not valid'
    }))
}

export const isNavigationToLinkedJourneyAllowed = (
  linkedJourney: Journey,
  state: StepState
): boolean => {
  const ajv = initAJV()
  const [initialStep] = linkedJourney.steps
  const schema = initialStep.schema

  return ajv.validate(schema, state)
}

export const transformMuiThemeToConcordeTheme = (
  theme: EpilotTheme
): ConcordeTheme => {
  return {
    typography: {
      fontFamily: 'Proxima Nova, Open Sans, Helvetica, Arial, sans-serif',
      fontSize: theme.typography?.fontSize || 16,
      h1: {
        fontSize: theme.typography?.h1?.fontSize || 28
      },
      h2: {
        fontSize: theme.typography?.h2?.fontSize || 24
      },
      h3: {
        fontSize: theme.typography?.h3?.fontSize || 20
      },
      h4: {
        fontSize: theme.typography?.h4?.fontSize || 16
      },
      h5: {
        fontSize: theme.typography?.h5?.fontSize || 14
      },
      h6: {
        fontSize: theme.typography?.h6?.fontSize || 12
      }
    },
    palette: {
      primary: theme.palette?.primary.main || '#005EB4',
      secondary: theme.palette?.secondary.main || '#913997',
      disabled: 'var(--neutral-variant-90)' || '#e0e2ec',
      error: theme.palette?.error.main || '#ff3a3f',
      background: {
        default: theme.palette?.background.default || '#FFFFFF',
        paper:
          (theme.muiOverrides?.MuiPaper?.root as CSSProperties)
            ?.backgroundColor || '#FFFFFF'
      },
      typography: {
        primary: theme.palette?.text.primary || '#001632',
        secondary: theme.palette?.text.secondary || '#717171',
        disabled: theme.palette?.text.disabled || '#43474e'
      }
    },
    spacing: theme.spacing || 8,
    shape: {
      borderRadius: theme.shape?.borderRadius || 4
    },
    breakpoints: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536
    }
  }
}
