import '../assets/StepperInput.css';var q = Object.defineProperty, D = Object.defineProperties;
var L = Object.getOwnPropertyDescriptors;
var _ = Object.getOwnPropertySymbols;
var k = Object.prototype.hasOwnProperty, x = Object.prototype.propertyIsEnumerable;
var g = (e, o, r) => o in e ? q(e, o, { enumerable: !0, configurable: !0, writable: !0, value: r }) : e[o] = r, l = (e, o) => {
  for (var r in o || (o = {}))
    k.call(o, r) && g(e, r, o[r]);
  if (_)
    for (var r of _(o))
      x.call(o, r) && g(e, r, o[r]);
  return e;
}, s = (e, o) => D(e, L(o));
var u = (e, o) => {
  var r = {};
  for (var t in e)
    k.call(e, t) && o.indexOf(t) < 0 && (r[t] = e[t]);
  if (e != null && _)
    for (var t of _(e))
      o.indexOf(t) < 0 && x.call(e, t) && (r[t] = e[t]);
  return r;
};
import { jsxs as F, jsx as a } from "react/jsx-runtime";
import { c as d } from "./index-rKuIKazb.js";
import { forwardRef as z } from "react";
import { B as G } from "./Button-Cab8Ve-j.js";
import { T as H } from "./TextField-DPuf80eb.js";
import { I as R } from "./Icon-W0dPy6Pi.js";
const J = "_root_1l6iw_1", K = "_label_1l6iw_22", M = "_adornment_1l6iw_74", O = "_error_1l6iw_108", n = {
  root: J,
  "root--fullWidth": "_root--fullWidth_1l6iw_17",
  label: K,
  "input-field-root": "_input-field-root_1l6iw_33",
  "input-field-root--fullWidth": "_input-field-root--fullWidth_1l6iw_66",
  adornment: M,
  "adornment-left": "_adornment-left_1l6iw_97",
  "adornment-right": "_adornment-right_1l6iw_102",
  error: O
}, Q = z(
  (e, o) => {
    const y = e, {
      startAdornment: r,
      endAdornment: t,
      onStartIconClick: c,
      onEndIconClick: v,
      label: f,
      isDisabled: h,
      isError: I,
      isRequired: N,
      errorColor: C,
      borderColor: S,
      id: b,
      style: w,
      containerProps: m,
      inputContainerProps: p,
      adornmentProps: i,
      iconProps: A,
      isFullWidth: W
    } = y, B = u(y, [
      "startAdornment",
      "endAdornment",
      "onStartIconClick",
      "onEndIconClick",
      "label",
      "isDisabled",
      "isError",
      "isRequired",
      "errorColor",
      "borderColor",
      "id",
      "style",
      "containerProps",
      "inputContainerProps",
      "adornmentProps",
      "iconProps",
      "isFullWidth"
    ]), E = {
      "--stepper-field-error-color": C,
      "--stepper-field-border-color": S
    }, T = l(l({}, w), E);
    return /* @__PURE__ */ F(
      "div",
      s(l({}, m), {
        className: d(
          n.root,
          W && n["root--fullWidth"],
          m == null ? void 0 : m.className
        ),
        style: T,
        children: [
          f && /* @__PURE__ */ F(
            "label",
            {
              className: d("Concorde-StepperInput", n.label),
              htmlFor: b,
              children: [
                f,
                N && /* @__PURE__ */ a(
                  "span",
                  {
                    "aria-hidden": "true",
                    className: d(
                      I && !h && n.error
                    ),
                    children: " *"
                  }
                )
              ]
            }
          ),
          /* @__PURE__ */ a(
            H,
            s(l({}, B), {
              borderColor: S,
              containerProps: s(l({}, p), {
                className: d(
                  "Concorde-StepperInput__InputField-Root",
                  n["input-field-root"],
                  W && n["input-field-root--fullWidth"],
                  p == null ? void 0 : p.className
                )
              }),
              endAdornment: /* @__PURE__ */ a(
                j,
                s(l({
                  onClick: v
                }, i), {
                  className: d(
                    "Concorde-StepperInput__Adornment-Right",
                    n["adornment-right"],
                    i == null ? void 0 : i.className
                  ),
                  children: t || /* @__PURE__ */ a(R, s(l({}, A), { name: "add" }))
                })
              ),
              errorColor: C,
              id: b,
              isDisabled: h,
              isError: I,
              isRequired: N,
              ref: o,
              startAdornment: /* @__PURE__ */ a(
                j,
                s(l({
                  onClick: c
                }, i), {
                  className: d(
                    "Concorde-StepperInput__Adornment-Left",
                    n["adornment-left"],
                    i == null ? void 0 : i.className
                  ),
                  children: r || /* @__PURE__ */ a(R, s(l({}, A), { name: "remove" }))
                })
              ),
              style: w,
              type: "tel"
            })
          )
        ]
      })
    );
  }
), j = (t) => {
  var c = t, {
    children: e,
    className: o
  } = c, r = u(c, [
    "children",
    "className"
  ]);
  return /* @__PURE__ */ a(
    G,
    l({
      className: d(
        "Concorde-StepperInput__Adornment",
        n.adornment,
        o
      ),
      color: "auto",
      label: e
    }, r)
  );
};
Q.displayName = "StepperInput";
export {
  Q as S
};
