export const trimObject = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      return
    }

    if (typeof value === 'string') {
      obj[key] = value.replace(/\s+/g, ' ').trim()
    } else if (Array.isArray(value)) {
      obj[key] = value.map((item) =>
        typeof item === 'string' ? item.replace(/\s+/g, ' ').trim() : item
      )
    } else if (typeof value === 'object') {
      obj[key] = trimObject(value as Record<string, unknown>)
    }
  })

  return obj
}
