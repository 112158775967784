import { Dispatch, SetStateAction } from 'react'

import { JourneyEmbedOptions, MessageData, MESSAGE_TYPES } from './types'
import {
  isJourneyEmbedOptions,
  isPartialJourneyEmbedOptions
} from './types.guard'

export const handleMessage = (
  handleUpdateOptions: Dispatch<SetStateAction<JourneyEmbedOptions | null>>,
  id: string
) => (message: MessageEvent<MessageData>) => {
  const { data } = message
  const { type, payload, journeyId } = data

  // return if the message is not for this journey
  if (id !== journeyId) return

  switch (type) {
    case MESSAGE_TYPES.UPDATE_JOURNEY:
    case MESSAGE_TYPES.INIT: {
      if (!isJourneyEmbedOptions(payload)) {
        console.error(
          `Error when handling message ${MESSAGE_TYPES.INIT}. Payload is not valid`,
          { payload }
        )

        return
      }

      console.info(
        `Initialized or updated Journey with id: ${journeyId} with following parameters`,
        { payload }
      )

      const reactEntryPoint = document.getElementById('root') as HTMLElement

      // add iframe resizer data if correct mode
      if (payload.mode === 'inline') {
        reactEntryPoint.classList.add('static') // removes position absolute if autoresize flag passed
        reactEntryPoint.style.height = '100%'
        reactEntryPoint.style.overflowY = 'visible'
      } else {
        reactEntryPoint.classList.remove('static')
        reactEntryPoint.style.height = '100vh'
        reactEntryPoint.style.overflowY = 'auto'
      }

      // merge options if prev options detected
      handleUpdateOptions((prev) => (prev ? { ...prev, ...payload } : payload))

      return
    }
    case MESSAGE_TYPES.ENTER_FULL_SCREEN: {
      handleUpdateOptions((prev) => {
        if ((!payload || isPartialJourneyEmbedOptions(payload)) && prev) {
          return {
            ...prev,
            ...payload,
            isFullScreenEntered: true
          }
        }

        return prev
      })

      return
    }
    case MESSAGE_TYPES.EXIT_FULL_SCREEN: {
      handleUpdateOptions((prev) => {
        if ((!payload || isPartialJourneyEmbedOptions(payload)) && prev) {
          return {
            ...prev,
            ...payload,
            isFullScreenEntered: false
          }
        }

        return prev
      })

      return
    }

    default:
      return
  }
}
