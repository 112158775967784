import { isUiSchemaWithElements } from '@epilot/journey-logic-commons'
import type { Journey } from '@epilot/journey-logic-commons'
import type { JourneyContextValue } from '@epilot/json-renderers'

export const journeyErrorMessageKeyMap = {
  ProductSelectionControl: 'product_selection_control_error',
  PersonalInformationControl: 'personal_information_control_error',
  GenericJourneyError: 'generic_journey_error',
  MultipleJourneyError: 'multiple_journey_errors',
  UploadPanelControl: 'upload_panel_control',
  PaymentControl: 'generic_journey_errors',
  ConsentsControl: 'generic_journey_errors',
  SubmissionNetworkError: 'submission_network_error'
} as const

export const blocksWithSpecificErrorKeys = [
  'ProductSelectionControl',
  'UploadPanelControl'
] as const

type JourneyContextErrors = NonNullable<JourneyContextValue['_errors']>

type JourneyContextStepError = JourneyContextErrors[number][number]

export const getBlockTypesForError = (
  currentStepErrors: JourneyContextStepError[],
  journey: Journey,
  currentStepIndex: number
) => {
  const stepUiSchema = journey.steps[currentStepIndex].uischema

  const currentStepUiElements = isUiSchemaWithElements(stepUiSchema)
    ? stepUiSchema.elements.flatMap((element) => element)
    : []

  const errorSchemas = currentStepErrors.map((error) =>
    'params' in error
      ? error.params?.missingProperty
      : 'instancePath' in error
      ? error.instancePath
      : undefined
  )

  const errorUiElements = currentStepUiElements
    ?.filter((element) => {
      const scope = element.scope

      return errorSchemas.some((schema) => schema && scope.includes(schema))
    })
    .filter(Boolean)
    .map((element) => element?.type)

  return errorUiElements
}

export const getHigherPriorityErrorKey = (
  erroredBlockTypes: string[] | undefined
): string => {
  if (erroredBlockTypes === undefined) {
    return journeyErrorMessageKeyMap['GenericJourneyError']
  }

  const higherPriorityErrorBlock = erroredBlockTypes.find((block) =>
    blocksWithSpecificErrorKeys.some((specificBlock) => specificBlock === block)
  )

  const errorKey = higherPriorityErrorBlock
    ? journeyErrorMessageKeyMap[higherPriorityErrorBlock]
    : journeyErrorMessageKeyMap[erroredBlockTypes[0]] ||
      journeyErrorMessageKeyMap['GenericJourneyError']

  return errorKey
}
