import {
  JOURNEY_EXIT_EVENT,
  JOURNEY_LOADING_EVENT
} from '@epilot/json-renderers'
import type { EventDetailType } from '@epilot/json-renderers'

export const dispatchLoadingEvent = (isLoading: boolean) => {
  const event = new CustomEvent<EventDetailType>(JOURNEY_LOADING_EVENT, {
    detail: {
      payload: {
        value: isLoading
      }
    }
  })

  document.dispatchEvent(event)
}

export const dispatchExitEvent = ({
  isCleanJourneyData
}: {
  isCleanJourneyData: boolean
}) => {
  const event = new CustomEvent<EventDetailType>(JOURNEY_EXIT_EVENT, {
    detail: {
      payload: {
        isCleanJourneyData
      }
    }
  })

  document.dispatchEvent(event)
}
