import crypto from 'crypto'

import { CONTROL_NAME } from '@epilot/journey-logic-commons'

export const toBeHashedControllerList = [
  CONTROL_NAME.PERSONAL_INFORMATION_CONTROL,
  CONTROL_NAME.PAYMENT_CONTROL,
  CONTROL_NAME.ADDRESS_BLOCK
]

export const hashValue = (value: string | Date): string => {
  if (typeof value === 'undefined' || JSON.stringify(value) === '{}') {
    return ''
  }

  const hash = crypto.createHash('sha256')

  if (value instanceof Date) {
    hash.update(value.toISOString())
  } else {
    hash.update(value)
  }

  const hashedOne = hash.digest('hex')

  return hashedOne
}

export const prepareTrackingData = (updatedState, steps) => {
  const mainState = structuredClone(updatedState)

  const processedMainState = {}

  mainState.forEach((state, index) => {
    const stateKeys = Object.keys(state)

    if (stateKeys.length === 0) {
      return
    }

    const step = steps[index]

    const stepName = step.name
    const elements = step.uischema?.elements || []

    const flattenElements = elements.flat() // Flatten the elements array if it is an array of arrays

    stateKeys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let matchingElement: any = undefined

      for (let index = 0; index < flattenElements.length; index++) {
        const element = flattenElements[index]

        if (element?.scope?.endsWith(key)) {
          matchingElement = element
          break
        } else if (Array.isArray(element?.elements)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          matchingElement = element.elements.find((item: any) =>
            item?.scope?.endsWith(key)
          )

          if (matchingElement) {
            break
          }
        }
      }

      if (matchingElement) {
        if (toBeHashedControllerList.includes(matchingElement.type)) {
          if (state[key] instanceof Date) {
            state[key] = hashValue(state[key].toISOString())
          } else if (typeof state[key] === 'object') {
            state[key] = recursivelyHashValues(state[key])
          } else {
            state[key] = hashValue(state[key])
          }
        }
      }
    })

    processedMainState[stepName] = state
  })

  return processedMainState
}

export const recursivelyHashValues = (obj) => {
  if (typeof obj !== 'object') {
    return hashValue(obj)
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = recursivelyHashValues(obj[key])
    }
  }

  return obj
}
