import '../assets/Link.css';var u = Object.defineProperty, C = Object.defineProperties;
var N = Object.getOwnPropertyDescriptors;
var l = Object.getOwnPropertySymbols;
var i = Object.prototype.hasOwnProperty, n = Object.prototype.propertyIsEnumerable;
var c = (o, s, r) => s in o ? u(o, s, { enumerable: !0, configurable: !0, writable: !0, value: r }) : o[s] = r, a = (o, s) => {
  for (var r in s || (s = {}))
    i.call(s, r) && c(o, r, s[r]);
  if (l)
    for (var r of l(s))
      n.call(s, r) && c(o, r, s[r]);
  return o;
}, d = (o, s) => C(o, N(s));
var m = (o, s) => {
  var r = {};
  for (var e in o)
    i.call(o, e) && s.indexOf(e) < 0 && (r[e] = o[e]);
  if (o != null && l)
    for (var e of l(o))
      s.indexOf(e) < 0 && n.call(o, e) && (r[e] = o[e]);
  return r;
};
import { jsx as v } from "react/jsx-runtime";
import { c as x } from "./index-rKuIKazb.js";
import { forwardRef as j } from "react";
const w = "_root_16f24_1", D = "_disabled_16f24_11", f = {
  root: w,
  disabled: D
}, R = j(
  (o, s) => {
    const t = o, {
      className: r,
      isDisabled: e = !1,
      color: b,
      hoverColor: _,
      style: k,
      children: p
    } = t, y = m(t, [
      "className",
      "isDisabled",
      "color",
      "hoverColor",
      "style",
      "children"
    ]), L = {
      "--link-color": b,
      "--link-hover-color": _
    }, h = a(a({}, k), L);
    return /* @__PURE__ */ v(
      "a",
      d(a({
        "aria-disabled": e,
        className: x(
          "Concorde-Link",
          f.root,
          e && f.disabled,
          r
        ),
        ref: s,
        style: h
      }, y), {
        children: p
      })
    );
  }
);
R.displayName = "Link";
export {
  R as L
};
