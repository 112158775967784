import { createContext, useContext, useEffect, useState } from 'react'

import {
  addAnalyticsEventListeners,
  removeAnalyticsEventListeners
} from '../analytics/domEvents'
import { analytics as analyticsPlugin } from '../analytics/plugin'
import {
  AnalyticsContextType,
  initializeAnalyticsPayload,
  JourneyAnalyticsPlugin
} from '../analytics/types'

const AnalyticsContext = createContext<AnalyticsContextType | null>(null)

export const AnalyticsProvider = ({ children }) => {
  const [analytics, setAnalytics] = useState<JourneyAnalyticsPlugin | null>(
    null
  )

  const initializeAnalytics = async (
    initAnalyticsPayload: initializeAnalyticsPayload
  ) => {
    try {
      const {
        journeyId,
        journeyLoadEndTime,
        journeyName,
        sessionStartTime
      } = initAnalyticsPayload
      const analytics = analyticsPlugin?.plugins?.journeyAnalyticsPlugin || null

      if (analytics) {
        addAnalyticsEventListeners(analytics)

        await analytics?.createSession({
          journeyId,
          journeyName,
          sessionStartTime
        })

        analytics?.addEvents([
          {
            type: 'journey_load_time',
            details: {
              journeyLoadTime:
                Math.abs(new Date().getTime() - journeyLoadEndTime.getTime()) /
                1000
            },
            timestamp: new Date().toISOString()
          }
        ])

        setAnalytics(analytics)
      }
    } catch (err) {
      console.error('Error initializing analytics', err)
    }
  }

  useEffect(() => {
    return () => {
      analytics && removeAnalyticsEventListeners(analytics)
    }
  }, [analytics])

  return (
    <AnalyticsContext.Provider value={{ analytics, initializeAnalytics }}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)

  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider')
  }

  return context
}
