import {
  ConsentData,
  ConsentTopics,
  DoubleOptInTopics,
  Source
} from '@epilot/journey-logic-commons'

import { OptIn } from '../services/submission-service'

import { POSSIBLE_JOURNEY_ENTITIES } from './generateJourneyDataSources'
import { DataSources } from './types'

type Consents = Record<string, ConsentData>

export function generateOptIns(
  dataSources: DataSources,
  journeyState: Record<string, unknown>[]
): {
  optins: OptIn[]
  consents: Consents
} {
  const opts: OptIn[] = []
  const consents: Consents = {}
  const sources = dataSources[POSSIBLE_JOURNEY_ENTITIES.CONSENTS]

  if (sources && sources.locations && journeyState) {
    const locations = sources.locations as Source[]

    locations.forEach((loc: Source) => {
      const data = journeyState[loc.stepIndex][loc.name]
      let userEmail = ''
      let userPhone = ''

      for (const userLocation of sources.userLocations) {
        userEmail = (journeyState[userLocation.stepIndex][
          userLocation.name
        ] as { email: string })?.email

        if (userEmail) break
      }
      for (const userLocation of sources.userLocations) {
        userPhone = (journeyState[userLocation.stepIndex][
          userLocation.name
        ] as { telephone: string })?.telephone

        if (userPhone) break
      }

      // each key is consent topic
      // accept only double optin topics here
      /**
       * @todo Should also check that data is not null
       * e.g. typeof data === 'object' && data !== null
       * After that, type castings for "data as NonNullable<typeof data>"
       * can be removed
       */
      typeof data === 'object' &&
        Object.keys(data as NonNullable<typeof data>).forEach((topic) => {
          const consent = (data as NonNullable<typeof data>)[
            topic
          ] as ConsentData

          // if topicValue is true and it is not yet part of the OptIns (it might be there are multiple blocks with the same topic = true)
          if (DoubleOptInTopics.includes(topic)) {
            if (
              userEmail &&
              consent.agreed &&
              opts.findIndex((opt) => opt.topic === topic) === -1
            ) {
              opts.push({
                // type: "OPT_IN"|"OPT_OUT",
                topic: topic,
                meta: {
                  user: {
                    username: userEmail
                  },
                  text: consent.text,
                  time: consent.time
                },
                identifier: [
                  ConsentTopics.PHONE_CALL_MARKETING,
                  ConsentTopics.SMS_MARKETING
                ].includes(topic)
                  ? userPhone
                  : userEmail
              })
            }
          } else {
            consents[topic] = consent
          }
        })
    })
  }

  return { optins: opts, consents: consents }
}
