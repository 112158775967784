import '../assets/Icon.css';var N = Object.defineProperty, _ = Object.defineProperties;
var b = Object.getOwnPropertyDescriptors;
var c = Object.getOwnPropertySymbols;
var l = Object.prototype.hasOwnProperty, i = Object.prototype.propertyIsEnumerable;
var n = (o, s, r) => s in o ? N(o, s, { enumerable: !0, configurable: !0, writable: !0, value: r }) : o[s] = r, t = (o, s) => {
  for (var r in s || (s = {}))
    l.call(s, r) && n(o, r, s[r]);
  if (c)
    for (var r of c(s))
      i.call(s, r) && n(o, r, s[r]);
  return o;
}, m = (o, s) => _(o, b(s));
var d = (o, s) => {
  var r = {};
  for (var e in o)
    l.call(o, e) && s.indexOf(e) < 0 && (r[e] = o[e]);
  if (o != null && c)
    for (var e of c(o))
      s.indexOf(e) < 0 && i.call(o, e) && (r[e] = o[e]);
  return r;
};
import { jsx as x } from "react/jsx-runtime";
import { c as z } from "./index-rKuIKazb.js";
import { forwardRef as j } from "react";
const w = "_root_1pes3_1", R = {
  root: w
}, S = j((o, s) => {
  const a = o, {
    name: r,
    className: e,
    variant: p = "rounded",
    color: f,
    hoverColor: u,
    size: y,
    style: h
  } = a, I = d(a, [
    "name",
    "className",
    "variant",
    "color",
    "hoverColor",
    "size",
    "style"
  ]), v = {
    "--icon-color": f,
    "--icon-hover-color": u,
    "--icon-size": y
  }, C = t(t({}, h), v);
  return /* @__PURE__ */ x(
    "span",
    m(t({
      "aria-hidden": "true",
      "aria-label": r,
      className: z(
        "Concorde-Icon",
        `material-symbols-${p}`,
        R.root,
        e
      ),
      ref: s,
      style: C
    }, I), {
      children: r
    })
  );
});
S.displayName = "Icon";
export {
  S as I
};
