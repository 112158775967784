import type { Step } from '@epilot/journey-logic-commons'
import type { HistoryStack } from '@epilot/json-renderers'

/**
 *
 * @param steps
 * @param injectionStepIndex represents the current step, we want to fill the history with steps before it
 * @returns
 */
export function getInitialHistoryStack(
  steps: Step[] = [],
  injectionStepIndex?: number
): HistoryStack[] | undefined {
  return typeof injectionStepIndex === 'number'
    ? steps
        ?.filter((_p, i) => i < injectionStepIndex)
        .map((stp, i) => ({
          stepIndex: i,
          pathname: stp.stepId,
          isInjected: true
        }))
    : undefined
}
