import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { ConfigCheckWrapper } from './ConfigCheckWrapper'
import { AnalyticsProvider } from './context/AnalyticsContext'
import { initDatadog } from './services/datadog'
import './index.css'
import { createShadowFormElement } from './shadow-form/shadow-form'
import { env } from './utils/config'
import { TRACE_KEYS, generateTraceId } from './utils/trace'

/**
 * WARNING: This should be always called and should happen before anything else,
 * thus making sure we can trace all sessions, including those that fail early.
 *
 * NOTE: This is supposed to be disabled for the local/test environments.
 */
initDatadog({
  disabled: ['test', 'dev'].includes(env('REACT_APP_STAGE'))
})

/**
 * Generates an unique trace id for this App's session.
 *
 * NOTE: This should be called before anything else, otherwise we might loose the ability
 * to trace the session to underlying errors/resources.
 */
generateTraceId(TRACE_KEYS.JOURNEY_SESSION_ID)

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('iframe-resizer').iframeResizerContentWindow

/**
 * Render App
 */
ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider>
      <ConfigCheckWrapper>
        <App />
      </ConfigCheckWrapper>
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

createShadowFormElement()
