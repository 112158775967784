import {
  LogicCondition,
  LogicTriggerEventName
} from '@epilot/journey-logic-commons'
import isEqual from 'fast-deep-equal/react'

export function getWhichBlockHasChanged(
  oldStat?: Record<string, unknown>,
  newStat?: Record<string, unknown>
) {
  if (!newStat) {
    return undefined
  }
  const newKeys = Object.keys(newStat)

  if (!oldStat) {
    return newKeys
  }

  return newKeys.filter((key) => !isEqual(newStat[key], oldStat[key]))
}

export const findLogicConditionsByEventType = (
  conditions: LogicCondition[],
  eventType: LogicTriggerEventName
): LogicCondition[] => {
  return conditions.filter((cond) => cond.event === eventType)
}
