import '../assets/TextField.css';var z = Object.defineProperty, G = Object.defineProperties;
var J = Object.getOwnPropertyDescriptors;
var c = Object.getOwnPropertySymbols;
var v = Object.prototype.hasOwnProperty, y = Object.prototype.propertyIsEnumerable;
var A = (o, r, t) => r in o ? z(o, r, { enumerable: !0, configurable: !0, writable: !0, value: t }) : o[r] = t, i = (o, r) => {
  for (var t in r || (r = {}))
    v.call(r, t) && A(o, t, r[t]);
  if (c)
    for (var t of c(r))
      y.call(r, t) && A(o, t, r[t]);
  return o;
}, b = (o, r) => G(o, J(r));
var B = (o, r) => {
  var t = {};
  for (var n in o)
    v.call(o, n) && r.indexOf(n) < 0 && (t[n] = o[n]);
  if (o != null && c)
    for (var n of c(o))
      r.indexOf(n) < 0 && y.call(o, n) && (t[n] = o[n]);
  return t;
};
import { jsxs as m, jsx as s } from "react/jsx-runtime";
import { c as l } from "./index-rKuIKazb.js";
import { forwardRef as K, useState as M } from "react";
const O = "_root_d9npo_2", P = "_label_d9npo_63", Q = "_input_d9npo_31", U = "_adornment_d9npo_137", V = "_error_d9npo_165", e = {
  root: O,
  "input-root": "_input-root_d9npo_31",
  "input-root-start": "_input-root-start_d9npo_41",
  "input-root-end": "_input-root-end_d9npo_45",
  "input-root-error": "_input-root-error_d9npo_49",
  "input-root-focused": "_input-root-focused_d9npo_53",
  "input-root-disabled": "_input-root-disabled_d9npo_57",
  label: P,
  "label-start": "_label-start_d9npo_79",
  "label-end": "_label-end_d9npo_83",
  "label-disabled": "_label-disabled_d9npo_87",
  "input-container": "_input-container_d9npo_92",
  input: Q,
  "input-label": "_input-label_d9npo_120",
  "input-error": "_input-error_d9npo_124",
  "input-disabled": "_input-disabled_d9npo_128",
  adornment: U,
  "adornment-start": "_adornment-start_d9npo_143",
  "adornment-end": "_adornment-end_d9npo_147",
  "helper-text": "_helper-text_d9npo_152",
  error: V
}, W = K(
  (o, r) => {
    const N = o, {
      label: t,
      startAdornment: n,
      endAdornment: _,
      containerProps: p,
      className: D,
      isRequired: I,
      isDisabled: d,
      isError: u,
      helperText: x,
      color: R,
      backgroundColor: S,
      borderColor: g,
      errorColor: j,
      style: k,
      id: f,
      onBlur: h,
      onFocus: F
    } = N, E = B(N, [
      "label",
      "startAdornment",
      "endAdornment",
      "containerProps",
      "className",
      "isRequired",
      "isDisabled",
      "isError",
      "helperText",
      "color",
      "backgroundColor",
      "borderColor",
      "errorColor",
      "style",
      "id",
      "onBlur",
      "onFocus"
    ]), [T, C] = M(!1), q = {
      "--text-field-color": R,
      "--text-field-background-color": S,
      "--text-field-border-color": g,
      "--text-field-error-color": j
    }, w = i(i({}, k), q);
    function H(a) {
      if (d) {
        a.preventDefault();
        return;
      }
      C(!0), F && F(a);
    }
    function L(a) {
      if (d) {
        a.preventDefault();
        return;
      }
      C(!1), h && h(a);
    }
    return /* @__PURE__ */ m(
      "div",
      b(i({}, p), {
        className: l(
          "Concorde-TextField",
          e.root,
          p == null ? void 0 : p.className
        ),
        style: w,
        children: [
          /* @__PURE__ */ m(
            "div",
            {
              className: l(
                "Concorde-TextField__Input-Root",
                e["input-root"],
                n && e["input-root-start"],
                _ && e["input-root-end"],
                d && e["input-root-disabled"],
                u && e["input-root-error"],
                T && e["input-root-focused"]
              ),
              children: [
                n && /* @__PURE__ */ s(
                  "div",
                  {
                    className: l(
                      "Concorde-TextField__Adornment",
                      "Concorde-TextField__Adornment-Start",
                      e.adornment,
                      e["adornment-start"]
                    ),
                    children: n
                  }
                ),
                /* @__PURE__ */ m("div", { className: e["input-container"], children: [
                  t && /* @__PURE__ */ m(
                    "label",
                    {
                      className: l(
                        "Concorde-TextField__Label",
                        e.label,
                        n && e["label-start"],
                        _ && e["label-end"],
                        d && e["label-disabled"]
                      ),
                      htmlFor: f,
                      children: [
                        t,
                        I && /* @__PURE__ */ s(
                          "span",
                          {
                            "aria-hidden": "true",
                            className: l(
                              u && !d && e.error
                            ),
                            children: " *"
                          }
                        )
                      ]
                    }
                  ),
                  /* @__PURE__ */ s(
                    "input",
                    b(i({}, E), {
                      "aria-hidden": d,
                      className: l(
                        "Concorde-TextField__Input",
                        e.input,
                        t && e["input-label"],
                        u && !T && e["input-error"],
                        d && e["input-disabled"],
                        D
                      ),
                      disabled: d,
                      id: f,
                      onBlur: L,
                      onFocus: H,
                      ref: r
                    })
                  )
                ] }),
                _ && /* @__PURE__ */ s(
                  "div",
                  {
                    className: l(
                      "Concorde-TextField__Adornment",
                      "Concorde-TextField__Adornment-End",
                      e.adornment,
                      e["adornment-end"]
                    ),
                    children: _
                  }
                )
              ]
            }
          ),
          x && /* @__PURE__ */ s(
            "p",
            {
              className: l(
                "Concorde-TextField__HelperText",
                e["helper-text"],
                u && !d && e.error
              ),
              children: x
            }
          )
        ]
      })
    );
  }
);
W.displayName = "TextField";
export {
  W as T
};
