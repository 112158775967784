import '../assets/MobileStepper.css';var D = Object.defineProperty, w = Object.defineProperties;
var F = Object.getOwnPropertyDescriptors;
var a = Object.getOwnPropertySymbols;
var v = Object.prototype.hasOwnProperty, C = Object.prototype.propertyIsEnumerable;
var S = (o, t, e) => t in o ? D(o, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : o[t] = e, l = (o, t) => {
  for (var e in t || (t = {}))
    v.call(t, e) && S(o, e, t[e]);
  if (a)
    for (var e of a(t))
      C.call(t, e) && S(o, e, t[e]);
  return o;
}, M = (o, t) => w(o, F(t));
var f = (o, t) => {
  var e = {};
  for (var s in o)
    v.call(o, s) && t.indexOf(s) < 0 && (e[s] = o[s]);
  if (o != null && a)
    for (var s of a(o))
      t.indexOf(s) < 0 && C.call(o, s) && (e[s] = o[s]);
  return e;
};
import { jsxs as j, jsx as p, Fragment as L } from "react/jsx-runtime";
import { c as i } from "./index-rKuIKazb.js";
import { forwardRef as R } from "react";
const V = "_root_1ju18_2", q = "_button_1ju18_45", r = {
  root: V,
  "position-bottom": "_position-bottom_1ju18_22",
  "position-top": "_position-top_1ju18_30",
  "variant-dots": "_variant-dots_1ju18_39",
  button: q,
  "step-dots": "_step-dots_1ju18_50",
  "step-dots-active": "_step-dots-active_1ju18_60"
}, z = R(
  (o, t) => {
    const m = o, {
      steps: e,
      activeStep: s,
      setActiveStep: _,
      nextButton: d,
      backButton: n,
      className: c,
      position: b = "static",
      style: B,
      variant: u = "dots",
      color: N,
      backgroundColor: g,
      stepBgColor: h,
      activeStepBgColor: k
    } = m, y = f(m, [
      "steps",
      "activeStep",
      "setActiveStep",
      "nextButton",
      "backButton",
      "className",
      "position",
      "style",
      "variant",
      "color",
      "backgroundColor",
      "stepBgColor",
      "activeStepBgColor"
    ]), x = {
      "--mobile-stepper-color": N,
      "--mobile-stepper-background-color": g,
      "--mobile-stepper-step-background-color": h,
      "--mobile-stepper-active-step-background-color": k
    }, A = l(l({}, B), x);
    return /* @__PURE__ */ j(
      "div",
      M(l({
        className: i(
          "Concorde-MobileStepper",
          r.root,
          c,
          {
            [r["position-top"]]: b === "top",
            [r["position-bottom"]]: b === "bottom"
          }
        ),
        ref: t,
        style: A
      }, y), {
        children: [
          /* @__PURE__ */ p(
            "div",
            {
              className: i(
                "Concorde-MobileStepper__Button",
                "Concorde-MobileStepper__BackButton",
                r.button
              ),
              children: n
            }
          ),
          /* @__PURE__ */ p(
            "div",
            {
              className: i("Concorde-MobileStepper__Steps", {
                [r["variant-dots"]]: u === "dots"
              }),
              children: /* @__PURE__ */ p(
                E,
                {
                  activeStep: s,
                  setActiveStep: _,
                  steps: e,
                  variant: u
                }
              )
            }
          ),
          /* @__PURE__ */ p(
            "div",
            {
              className: i(
                "Concorde-MobileStepper__Button",
                "Concorde-MobileStepper__NextButton",
                r.button
              ),
              children: d
            }
          )
        ]
      })
    );
  }
);
function E({
  variant: o,
  steps: t,
  activeStep: e,
  setActiveStep: s
}) {
  const _ = Array.from({ length: t });
  return o === "dots" ? /* @__PURE__ */ p(L, { children: _.map((d, n) => {
    const c = n === e;
    return /* @__PURE__ */ p(
      "button",
      {
        className: i(
          "Concorde-MobileStepper__Step",
          "Concorde-MobileStepper__Step-Dots",
          r["step-dots"],
          c && "Concorde-MobileStepper__Step-Dots--active",
          c && r["step-dots-active"]
        ),
        onClick: () => {
          s && s(n);
        }
      },
      n
    );
  }) }) : /* @__PURE__ */ j("div", { className: i("Concorde-MobileStepper__Step"), children: [
    e + 1,
    " / ",
    t
  ] });
}
z.displayName = "MobileStepper";
export {
  z as M
};
