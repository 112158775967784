import { StepState } from '@epilot/journey-logic-commons'
import { HistoryStack } from '@epilot/json-renderers'
import isEqual from 'fast-deep-equal/es6'
import { useEffect } from 'react'

import { publishFormEventMessage } from '../components/JourneyPage/embedJourneyPublishers'

import { usePrevious } from './usePrevious'

export const useIsDirty = (
  initialValues: StepState[],
  currentValues: StepState[],
  journeyId: string,
  historyStack: HistoryStack[]
): boolean => {
  const visitedSteps = historyStack.map((h) => h.stepIndex)

  const cleanInitialValues = initialValues
    .map(cleanStepValues)
    .filter((_v, stepIndex) => visitedSteps.includes(stepIndex))
  const cleanCurrentValues = currentValues
    .map(cleanStepValues)
    .filter((_v, stepIndex) => visitedSteps.includes(stepIndex))

  const isDirty = !isEqual(cleanInitialValues, cleanCurrentValues)
  const isDirtyPrev = usePrevious(isDirty)

  useEffect(() => {
    if (!isEqual(isDirtyPrev, isDirty)) {
      publishFormEventMessage({ journeyId, isDirty })
    }
  }, [isDirtyPrev, isDirty, journeyId])

  return isDirty
}

const cleanStepValues = (stepValues: StepState) => {
  if (!stepValues) {
    return stepValues
  }

  const keys = Object.keys(stepValues)
  const cleanedValue = {}

  for (const key of keys) {
    // Get blockValue
    let blockValue = stepValues[key]

    // If blockValue is an object, also clean that object
    if (blockValue && typeof blockValue === 'object') {
      blockValue = cleanBlockValues(blockValue)
    }
    // If blockValue is defined, consider it for comparison
    if (blockValue || typeof blockValue === 'boolean') {
      cleanedValue[key] = stepValues[key]
    }
  }

  return cleanedValue
}

const cleanBlockValues = (blockValue) => {
  let hasValue = false
  const cleanValue = {}

  for (const key of Object.keys(blockValue)) {
    // Ignore isValid for dirty check or for the unique case of countryCode
    if (key === '_isValid' || key === 'countryCode') {
      continue
    }

    // Only Keep values that are defined
    if (blockValue[key]) {
      hasValue = true
      cleanValue[key] = blockValue[key]
    }
  }

  return hasValue ? cleanValue : undefined
}
