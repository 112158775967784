import '../assets/Image.css';var D = Object.defineProperty, F = Object.defineProperties;
var H = Object.getOwnPropertyDescriptors;
var m = Object.getOwnPropertySymbols;
var k = Object.prototype.hasOwnProperty, u = Object.prototype.propertyIsEnumerable;
var b = (e, a, o) => a in e ? D(e, a, { enumerable: !0, configurable: !0, writable: !0, value: o }) : e[a] = o, n = (e, a) => {
  for (var o in a || (a = {}))
    k.call(a, o) && b(e, o, a[o]);
  if (m)
    for (var o of m(a))
      u.call(a, o) && b(e, o, a[o]);
  return e;
}, g = (e, a) => F(e, H(a));
var E = (e, a) => {
  var o = {};
  for (var r in e)
    k.call(e, r) && a.indexOf(r) < 0 && (o[r] = e[r]);
  if (e != null && m)
    for (var r of m(e))
      a.indexOf(r) < 0 && u.call(e, r) && (o[r] = e[r]);
  return o;
};
import { jsxs as P, jsx as s, Fragment as R } from "react/jsx-runtime";
import { c } from "./index-rKuIKazb.js";
import { forwardRef as T, useState as N, useEffect as q } from "react";
const A = "_root_7ekbz_1", B = "_loading_7ekbz_1", G = "_image_7ekbz_18", d = {
  root: A,
  "image-placeholder": "_image-placeholder_7ekbz_18",
  loading: B,
  "image-error": "_image-error_7ekbz_28",
  image: G,
  "image-loaded": "_image-loaded_7ekbz_48"
}, J = T((e, a) => {
  const p = e, {
    alt: o,
    src: r,
    className: z,
    containerProps: l,
    onLoad: _,
    onError: f,
    fallbackSrc: L,
    loadingPlaceholder: x,
    isDefaultLoaded: C = !0,
    errorText: v
  } = p, j = E(p, [
    "alt",
    "src",
    "className",
    "containerProps",
    "onLoad",
    "onError",
    "fallbackSrc",
    "loadingPlaceholder",
    "isDefaultLoaded",
    "errorText"
  ]), [i, S] = N(C), [h, I] = N(!1), w = (t) => {
    i || S(!0), _ && _(t);
  }, y = (t) => {
    I(!0), f && f(t);
  };
  return q(() => {
    I(!1);
  }, [r]), /* @__PURE__ */ P(
    "div",
    g(n({}, l), {
      className: c(
        "Concorde-Image",
        d.root,
        l == null ? void 0 : l.className
      ),
      children: [
        !i && !h && /* @__PURE__ */ s(R, { children: x || /* @__PURE__ */ s(
          "div",
          {
            className: c(
              "Concorde-Image__Placeholder",
              d["image-placeholder"]
            )
          }
        ) }),
        h ? /* @__PURE__ */ s(
          "div",
          {
            className: c(
              "Concorde-Image__Error",
              d["image-error"]
            ),
            children: /* @__PURE__ */ s("p", { children: v || "Image failed to load" })
          }
        ) : /* @__PURE__ */ s(
          "img",
          g(n({}, j), {
            alt: o,
            className: c(
              "Concorde-Image__Image",
              d.image,
              i && d["image-loaded"],
              z
            ),
            onError: y,
            onLoad: w,
            ref: a,
            src: r || L
          })
        )
      ]
    })
  );
});
J.displayName = "Image";
export {
  J as I
};
