import '../assets/List.css';var D = Object.defineProperty;
var C = Object.getOwnPropertySymbols;
var f = Object.prototype.hasOwnProperty, N = Object.prototype.propertyIsEnumerable;
var L = (t, s, e) => s in t ? D(t, s, { enumerable: !0, configurable: !0, writable: !0, value: e }) : t[s] = e, l = (t, s) => {
  for (var e in s || (s = {}))
    f.call(s, e) && L(t, e, s[e]);
  if (C)
    for (var e of C(s))
      N.call(s, e) && L(t, e, s[e]);
  return t;
};
var a = (t, s) => {
  var e = {};
  for (var o in t)
    f.call(t, o) && s.indexOf(o) < 0 && (e[o] = t[o]);
  if (t != null && C)
    for (var o of C(t))
      s.indexOf(o) < 0 && N.call(t, o) && (e[o] = t[o]);
  return e;
};
import { jsx as m } from "react/jsx-runtime";
import { c as r } from "./index-rKuIKazb.js";
import { forwardRef as w } from "react";
const x = "_list_d4o3a_2", i = {
  list: x,
  "list-item": "_list-item_d4o3a_11",
  "list-item-clickable": "_list-item-clickable_d4o3a_25",
  "list-item-selected": "_list-item-selected_d4o3a_33",
  "list-item-disabled": "_list-item-disabled_d4o3a_42",
  "list-item-content": "_list-item-content_d4o3a_49",
  "list-item-adornment": "_list-item-adornment_d4o3a_55"
}, A = w(
  (t, s) => {
    const d = t, { as: e = "ul", className: o } = d, c = a(d, ["as", "className"]);
    return /* @__PURE__ */ m(
      e,
      l({
        className: r("Concorde-List", i.list, o),
        ref: s
      }, c)
    );
  }
), R = (t) => {
  const k = t, {
    onClick: s,
    className: e,
    isClickable: o,
    isSelected: c,
    isDisabled: n,
    style: d,
    hoverColor: p,
    hoverBgColor: y,
    selectedColor: I,
    selectedBgColor: g
  } = k, b = a(k, [
    "onClick",
    "className",
    "isClickable",
    "isSelected",
    "isDisabled",
    "style",
    "hoverColor",
    "hoverBgColor",
    "selectedColor",
    "selectedBgColor"
  ]), h = {
    "--concorde-list-item-hover-color": p,
    "--concorde-list-item-hover-background-color": y,
    "--concorde-list-item-selected-color": I,
    "--concorde-list-item-selected-background-color": g
  }, u = l(l({}, d), h);
  function v(_) {
    n || (_.key === "Enter" || _.key === " ") && (_.preventDefault(), _.target.click());
  }
  return s ? /* @__PURE__ */ m(
    "li",
    l({
      "aria-disabled": n,
      "aria-selected": c,
      className: r(
        "Concorde-ListItem",
        i["list-item"],
        i["list-item-clickable"],
        n && i["list-item-disabled"],
        c && i["list-item-selected"],
        e
      ),
      onClick: n ? void 0 : s,
      onKeyDown: v,
      role: "option",
      style: u,
      tabIndex: 0
    }, b)
  ) : /* @__PURE__ */ m(
    "li",
    l({
      className: r(
        "Concorde-ListItem",
        i["list-item"],
        o && i["list-item-clickable"],
        c && i["list-item-selected"],
        e
      ),
      style: u
    }, b)
  );
}, q = (t) => {
  const o = t, { className: s } = o, e = a(o, ["className"]);
  return /* @__PURE__ */ m(
    "div",
    l({
      className: r(
        "Concorde-ListItem__Content",
        i["list-item-content"],
        s
      )
    }, e)
  );
}, z = (t) => {
  const o = t, { className: s } = o, e = a(o, ["className"]);
  return /* @__PURE__ */ m(
    "div",
    l({
      className: r(
        "Concorde-ListItem__Adornment",
        i["list-item-adornment"],
        s
      )
    }, e)
  );
};
A.displayName = "List";
export {
  A as L,
  R as a,
  q as b,
  z as c
};
