declare global {
  interface Window {
    journeyAppConfig: EnvType & {
      [key: string]: unknown
    }
  }
}

// change with your own variables
type EnvType = {
  // The below is loaded from the environment config
  REACT_APP_STAGE: string
  REACT_APP_JOURNEYS_API_BASE_URL: string
  REACT_APP_JOURNEYS_SUBMISSION_API: string
  REACT_APP_JOURNEYS_PRICING_API: string
  REACT_APP_JOURNEY_BUILDER_URL: string
  REACT_APP_JOURNEY_BASE_URL: string
  REACT_APP_JOURNEY_URL: string
  REACT_APP_ADDRESS_SUGGESTIONS_API: string
  REACT_APP_DATADOG_APPLICATION_ID: string
  REACT_APP_DATADOG_CLIENT_TOKEN: string
  REACT_APP_FILE_API_URL: string
  REACT_APP_ANALYTICS_API_BASE_URL: string
  REACT_APP_IMAGE_PREVIEW_API_URL: string
  REACT_APP_GOOGLE_MAPS_API_URL: string
  REACT_APP_PRICING_API_URL: string
  REACT_APP_ADDRESS_API_URL: string
  REACT_APP_ADDRESS_SUGGESTIONS_API_URL: string
  REACT_APP_PREVIOUS_PROVIDER_URL: string
  REACT_APP_ENTITY_API_URL: string
  REACT_APP_CUSTOMER_PORTAL_API_URL: string
  REACT_APP_METERING_API_URL: string

  // The below is always added during the build
  VERSION: string
  REACT_APP_IS_JEST: boolean
}

let env: EnvType = {
  ...process.env,
  ...window.journeyAppConfig
}

/**
 * As a quick and dirty fallback for the local development build,
 * we simply inject the variables into the env after reading directly from the file system.
 */
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const envConfig = require('./.env-cmdrc.json').local

  env = { ...env, ...envConfig }
}

export { env }
