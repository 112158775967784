import {
  makeStyles,
  createStyles,
  MobileStepperProps,
  Theme,
  EpilotTheme
} from '@epilot/journey-elements'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

export const useStepStyles = makeStyles((theme) =>
  createStyles({
    stepStyles: {
      cursor: 'pointer',
      '& .MuiStepIcon-root': {
        fontSize: '32px'
      },
      '& .MuiStepLabel-label': {
        marginTop: theme.spacing(2)
      },
      '& .MuiStepConnector-alternativeLabel': {
        top: theme.spacing(4)
      }
    }
  })
)

export const useStyles = makeStyles((theme: Theme & EpilotTheme) => ({
  root: {
    background:
      (theme?.muiOverrides?.MuiPaper?.root as CSSProperties)?.backgroundColor ||
      theme.palette.background.paper,
    color: theme.typography.body1.color,
    height: 40,
    width: '100%'
  },
  paper: {
    padding: 0
  },
  dot: {
    margin: '0 8px',
    width: 12,
    height: 12
  },
  hiddenButton: {
    visibility: 'hidden'
  },
  visibleButton: {
    visibility: 'visible',
    display: 'block'
  },
  stepper: {
    marginBottom: 20,
    justifyContent: 'space-between'
  },
  activeStep: {
    background: 'transparent'
  },
  mobileStepperStyles: ({ activeStep = 0, steps = 0 }: MobileStepperProps) => {
    const classes = {}

    for (let i = 0; i < steps; i++) {
      Object.assign(classes, {
        [`& .MuiMobileStepper-dot:nth-child(${i + 1})`]: {
          background:
            i + 1 <= activeStep + 1
              ? theme.palette.primary.main
              : theme.palette.divider
        }
      })
    }

    return classes
  }
}))
