export function scrollToThePageTop() {
  // The timeout is required as Safari does not preserve scrolling postion if content is removed from the page.
  // We remove content due to remounting with setForceRender as described here https://e-pilot.atlassian.net/browse/ER-2112
  // Ref task: https://e-pilot.atlassian.net/browse/ER-2513

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any

  if (isSafari) {
    setTimeout(() => {
      if ('parentIFrame' in win) {
        win['parentIFrame'].scrollToOffset(0, 0)
      } else {
        document.querySelector('header')?.scrollIntoView()
      }
    }, 100)
  } else if ('parentIFrame' in win) {
    win['parentIFrame'].scrollToOffset(0, 0)
  } else {
    document.querySelector('header')?.scrollIntoView()
  }
}
