import {
  EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE,
  publishEmbeddedJourneyMessage
} from '@epilot/json-renderers'

/**
 * Embeded journey publishers.
 */
export const publishExitFullScreenMessage = ({
  journeyId,
  isLauncherJourney
}: {
  journeyId: string
  isLauncherJourney: boolean
}) => {
  /**
   * Ensures support for legacy listeners
   */
  publishEmbeddedJourneyMessage(
    EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.LEGACY_EXIT_FULL_SCREEN,
    journeyId
  )
  publishEmbeddedJourneyMessage(
    EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.EXIT_FULL_SCREEN,
    journeyId,
    { isLauncherJourney }
  )
}

export const publishEnterFullScreenMessage = ({
  journeyId,
  isLauncherJourney
}: {
  journeyId: string
  isLauncherJourney: boolean
}) =>
  publishEmbeddedJourneyMessage(
    EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.ENTER_FULL_SCREEN,
    journeyId,
    { isLauncherJourney }
  )

export const publishCloseJourneyMessage = ({
  journeyId,
  isDirty
}: {
  journeyId: string
  isDirty: boolean
}) =>
  publishEmbeddedJourneyMessage(
    EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.CLOSE_JOURNEY,
    journeyId,
    {},
    { isDirty }
  )

export const publishFormEventMessage = ({
  journeyId,
  isDirty
}: {
  journeyId: string
  isDirty: boolean
}) =>
  publishEmbeddedJourneyMessage(
    EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.FORM_EVENT,
    journeyId,
    { isDirty }
  )

export const publishJourneyLoadedMessage = ({
  journeyId
}: {
  journeyId: string
}) =>
  publishEmbeddedJourneyMessage(
    EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.JOURNEY_LOADED,
    journeyId
  )
