import { Client, getClient } from '@epilot/analytics-client'

import { env } from '../utils/config'

const baseURL = env('REACT_APP_ANALYTICS_API_BASE_URL')
let analyticsClient: Client | null = null

export const getAnalyticsClient = (journeyToken: string) => {
  if (!analyticsClient) {
    analyticsClient = getClient()

    analyticsClient.defaults.baseURL = baseURL
  }

  analyticsClient.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${journeyToken}`

  return analyticsClient
}
